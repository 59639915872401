import React from 'react';
import RichTextForm from './richTexts/Form';
import { updateRichText } from '../api';
import Turbolinks from 'turbolinks';

export default class EditRichText extends React.Component {
  editRichText = (rich_text) => {
    const {
      preview_content,
      content,
      title,
      preview_image
    } = rich_text;
    const params = {
      preview_content, title, content, preview_image,
      id: this.props.rich_text.id,
      hapyning_id: this.props.hapyning_id,
      post_id: this.props.post_id
    }

    return updateRichText(params).done(({url}) => Turbolinks.visit(url))
  }

  render() {
    return (
      <RichTextForm rich_text={this.props.rich_text} hapyningId={this.props.hapyning_id} save={this.editRichText} />
    );
  }
}
