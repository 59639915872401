import React from 'react';
import ResourceForm from './resources/Form';

import { updateResource, updateRichText, updateGallery, updateFileLibrary,
  updateLinkCollection, deleteRichText, deleteGallery, deleteFileLibrary,
  deleteLinkCollection, createFileLibrary, createLinkCollection, createGallery,
  createRichText, deleteVideoLibrary
} from '../api';
import Turbolinks from 'turbolinks';

const upgradeOldResource = ({preview_content, ...resource}) => ({
  ...resource,
  preview_content: preview_content || resource.attachable?.preview_content || ""
});

const updateAttachable = async (previous_attachable, attachable, resource) => {
  if(previous_attachable.type !== attachable.type){
    switch (previous_attachable.type) {
      case "RichText":
        await deleteRichText(previous_attachable.id);
        break;
      case "Gallery":
        await deleteGallery(previous_attachable.id);
        break;
      case "FileLibrary":
        await deleteFileLibrary(previous_attachable.id);
        break;
      case "LinkCollection":
        await deleteLinkCollection(previous_attachable.id);
        break;
      case "VideoLibrary":
        await deleteVideoLibrary(previous_attachable.id);
        break;
    }
  }

  switch (attachable.type) {
    case "RichText":
      const {preview_content, title} = resource;
      if(typeof attachable.id === 'undefined'){
        const {rich_text} = await createRichText({
          preview_content,
          preview_image: attachable.preview_image,
          content: attachable.content,
          title,
        });
        return rich_text.id;
      }
      else{
        const {rich_text} = await updateRichText({
          id: attachable.id,
          preview_content,
          preview_image: attachable.preview_image,
          content: attachable.content,
          title,
        });
        return rich_text.id;
      }
    case "Gallery":
      let gallery;
      if(typeof attachable.id === 'undefined'){
        gallery = await createGallery(attachable.images);
      }
      else{
        gallery = await updateGallery(null,attachable);
      }
      return gallery.id;
    case "FileLibrary":
      let fileLibrary;
      if(typeof attachable.id === 'undefined'){
        fileLibrary = await createFileLibrary(attachable.files);
      }
      else{
        fileLibrary = await updateFileLibrary(null, attachable);
      }
      return fileLibrary.id;
    case "LinkCollection":
      let linkCollection;
      if(typeof attachable.id === 'undefined'){
        linkCollection = await createLinkCollection(attachable);
      } 
      else{
        linkCollection = await updateLinkCollection(attachable);
      }
      return linkCollection.id;
  }
}
export default class EditResource extends React.Component {
  updateResource = async ({previous_attachable, attachable, ...resource}) => {
    const referringPath  = this.props.referring_path
    let newTypeId = await updateAttachable(previous_attachable, attachable, resource);
    return new Promise((resolve, reject) => {
      updateResource({...resource, attachable_id: attachable.id === undefined ? newTypeId : attachable.id, attachable_type: attachable.type})
      .done(({url}) => resolve(Turbolinks.visit(referringPath || url)))
      .fail((error) => reject(error));
    });
  }

  cancel = () => {
    if (this.props.referring_path) {
      Turbolinks.visit(this.props.referring_path);
    } else {
      window.history.back();
    }
  }

  render() {
    return (
      <ResourceForm
        title="Edit Resource"
        hapyningId={this.props.hapyning_id}
        save={this.updateResource}
        resource={upgradeOldResource(this.props.resource)}
        edit={true}
      />
    );
  }
}
