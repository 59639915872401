import React from 'react';
import EMPTY_STATE_ADD_CONTENT from "empty-state-add-content.svg"

export const EmptyPosts = ({showNewPost}) => {
  if (showNewPost){
    return (
      <>
        <h3>Looks like there aren't any posts yet.</h3>
        <img src={EMPTY_STATE_ADD_CONTENT} alt=""/>
      </>
    )
  } else {
    return (
      <>
        <h3>Looks like there aren't any posts!<br/>Check back later!</h3>
        <img src={EMPTY_STATE_ADD_CONTENT} alt=""/>
      </>
    )
  }
}

export default EmptyPosts;
