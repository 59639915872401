import React from 'react';
import VideoPreview from './VideoPreview';
import ImageGallery from './ImageGallery';
import LinkCollectionPreview from './previews/LinkCollectionPreview';
import FileLibraryResource from './FileLibraryResource';
import GalleryImagesGrid from './galleryImagesGrid';

const COUNT_FROM = 5;

export default class PostResourceCard extends React.Component {
  state = {
    ...this.props,
  }
  navigateToResource = () => {
    const {post} = this.state;
    window.location = `${post.resource.hapyning_id}/resources/${post.resource.id}`;
  }
  render() {
    const { post } = this.state;
    const rich_text = post.resource?.attachable?.type == "RichText" || false
    return (
      <>
        {post.resource &&
          <div className="resource-header-details">
            <div className="post uk-card uk-card-default">
              <div className="uk-card-header resource-header">
                <div className="resource-header-top uk-margin-small-top">
                  <p className="header-title resource-style ">RESOURCE</p>
                </div>
                  <div className="resource-preview-text">
                    <h4 className="uk-margin-remove-vertical">{post.resource.title}</h4>
                    <div className="article-header-details">
                        <p className="uk-text-meta uk-margin-remove-vertical">
                          {post.resource.updated_at > post.resource.created_at && "Last updated " +
                            new Date(post.resource.updated_at).toDateString()
                          }
                        </p>
                      </div>
                    <div className="resource-preview-description">
                      {post.resource.preview_content && <p>{post.resource.preview_content}</p>}
                    </div>
                  </div>
                  {rich_text && (
                    <div className="center-align">
                      <a className="resource-richtext-preview" onClick={this.navigateToResource}>CONTINUE READING</a>
                    </div>
                    )
                  }

                {
                post.resource.attachable.preview_image &&
                <img className="resource-preview-image" src={post.resource.attachable.preview_image}></img>
                }
              </div>
            </div>
            <div className="post-body uk-card-body ck-content">
              <div className="mobile-content">
                {
                  post.resource.attachable.type === "VideoLibrary"
                  && <VideoPreview attachment={post.resource.attachable} />
                }
                {
                  post.resource.attachable.type === "Gallery"
                  &&  <GalleryImagesGrid images={post.resource.attachable.images} countFrom={COUNT_FROM}/>
                }
                {
                  post.resource.attachable.type === "LinkCollection"
                  && <div className="uk-vertical-align"><LinkCollectionPreview attachment={post.resource.attachable} classes="link-collection-preview" /></div>
                }
                {
                  post.resource.attachable.type === "FileLibrary"
                  && <FileLibraryResource attachable={post.resource.attachable} />
                }
              </div>
            </div>
          </div>
        }</>
    )
  }
}
