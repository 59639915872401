import React from 'react';
import classnames  from 'classnames';
import ReactTimeAgo from 'react-time-ago';
import TimeAgo from 'javascript-time-ago';

import { customLabels, customStyle } from '../helpers/time.js';
import { createLinksForUrls } from '../helpers/links.js';
import MoreOptions from "../../components/shared/MoreOptions";
import Avatar from '../Avatar';
import AlertModal from '../AlertModal';
import * as constants from "../../constants";
import * as api from "../../api";
import Alert from '@mui/material/Alert';

TimeAgo.addLabels('en', 'custom', customLabels);

class Comment extends React.Component {
  state = {
    alert: false,
    message: "",
    type: "success"
  }

  deleteComment = () => {
    const {comment: {id}} = this.props;
    $.ajax({url: `/comments/${id}`, type: 'DELETE'})
    .done(() => {
      this.props.removeComment(id);
    })
    .fail(() => {
      console.error("Failed to delete Comment");
    });;
  }

  blockCommentAuthor = (
    blockedById,
    blockedToId,
    blockedToFullName
  ) => {
    api.blockUser(blockedById, blockedToId)
      .done(() => {
        const key = constants.BLOCKED_USER_SESSION_KEY;
        sessionStorage.setItem(key, blockedToFullName);
        const message = `User ${blockedToFullName} is now blocked`;
        this.setAlert(message,'success');
        window.location = `/`;
      })
      .fail(() => {
        const message = `Failed to block ${blockedToFullName}`;
        this.setAlert(message, 'error');
      });
  }

  setAlert = (message, type) => {
    this.setState({alert: true, message: message, type: type});
    setTimeout(() => {
      this.setState({alert: false});
         }, 5000);
  }

  render() {
    const {comment, currentUser, canComment} = this.props;
    const { alert, message, type } = this.state
    const blockCommentAuthorModalId = `block-comment-${comment.author_id}-alert-modal`;
    const alertModalId = `comment-${comment.id}-alert-modal`;
    const isCommentAuthor = currentUser && comment.author_id === currentUser.id
    const canBlockCommentAuthor = !isCommentAuthor;
    const canModifyComment = isCommentAuthor && canComment;
    const showMoreOptions = canModifyComment ||  canBlockCommentAuthor;

    return (
      <div className={classnames("post-comment", {"former-member": comment.former_member})} id={`post-comment-${comment.id}`}>
        {alert && ( <Alert severity={type} className="fixedAlert" onClose={() => {this.setState({alert: false});}}>{message}</Alert> )}
        <div className="post-comment-avatar">
          <Avatar avatar={comment.avatar} />
        </div>
        <div className="post-comment-name">
          {comment.full_name}
        </div>
        <div className="post-comment-timestamp">
          <ReactTimeAgo
            date={new Date(comment.created_at)}
            timeStyle={customStyle}
            round="floor"/>
        </div>
        {showMoreOptions && (
          <div className="post-comment-menu">
            <MoreOptions title="Comment Options" dotClasses="uk-float-right">
              {!isCommentAuthor && (
                <li>
                  <a href="#" uk-toggle={`target: #${blockCommentAuthorModalId}`}>Block Comment Author</a>
                  <AlertModal
                    title="Confirm Block Member"
                    bodyText="You will be able to unblock this member from your account settings at any time"
                    actionText="Block Member"
                    action={() => this.blockCommentAuthor(currentUser.id, comment.author_id, comment.full_name)}
                    elementId={blockCommentAuthorModalId}
                  />
                </li>
              )}
              {canModifyComment && (
                <React.Fragment>
                  <li><a href="#" onClick={this.props.markCommentEditable()}>Edit</a></li>
                  <li>
                    <a href="#" uk-toggle={`target: #${alertModalId}`}>Delete</a>
                    <AlertModal
                      title="Confirm Delete Comment"
                      bodyText="Are you sure you want to delete this comment? This cannot be undone."
                      actionText="Delete Comment"
                      action={this.deleteComment}
                      elementId={alertModalId} />
                  </li>
                </React.Fragment>
              )}
            </MoreOptions>
          </div>
        )}
        <div className="post-comment-content"
          dangerouslySetInnerHTML={createLinksForUrls(comment.content)}/>
      </div>
    );
  }
}

export default Comment;
