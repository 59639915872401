import React from 'react';

export default function MaterialIcon({ block = false, name, className = '', ...props }) {
  const Component = block ? 'div' : 'span';
  return (
    <Component className={`material-icons ${className}`} aria-hidden="true" {...props}>
      {name}
    </Component>
  );
}
