jQuery(function($) {
  $(document).ready(function() {
    setTimeout(function() {
      $('.uk-alert').slideUp();
    }, 1500);
  });

  $(document).on('turbolinks:load', function() {
    setTimeout(function() {
      $('.uk-alert').slideUp();
    }, 1500);
  })

  $(document).on('click', '.toggle-sidebar', function() {
    const $sidebar = $(this).closest('.hapynings-sidebar');
    $sidebar.toggleClass('collapsed');
    if (!$sidebar.hasClass('mobile')) {
      $.ajax('/session_ui_state', {
        type: 'put',
        contentType: 'application/json',
        data: JSON.stringify({
          ui_state: {
            hapynings_sidebar_collapsed: $sidebar.hasClass('collapsed')
          }
        })
      });
    }
    Turbolinks.clearCache();
  });

  $(document).on('moved', '.hapyning-list', function() {
    const newPositions = $(this).find("li").map(function(position) {
      return {hapyning_id: $(this).data("hapyning-id"), position};
    }).get();
    $.ajax('/memberships/', {
      type: 'patch',
      contentType: 'application/json',
      data: JSON.stringify({memberships: newPositions})
    });
    Turbolinks.clearCache();
  });

  $(document).on('click', '.hamburger-menu', function(e) {
    e.preventDefault();

    const $parentSideBar = $('.hapynings-sidebar');
    if (!$parentSideBar.hasClass('collapsed')) {
      $parentSideBar.addClass('collapsed mobile');
    }
    $('#panel-navigation').addClass('active')
      .on('click', _.noop); // fixes safari bug
    Turbolinks.clearCache();
  });

  $(document).on('click', '#panel-navigation', function(e) {
    if (e.target === this) $(this).removeClass('active');
  });
});
