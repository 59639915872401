import React from 'react';
import Avatar from '../Avatar';
import TextareaAutosize from 'react-autosize-textarea';

export default function PostContentField({avatar, content, updateInput, submitOnEnter, setFocus}) {
  return (
    <div className="post-form-field">
      <Avatar avatar={avatar} className="post-form-author"/>
      <div className="field">
        <div className="uk-form-controls">
          <label htmlFor="content" className="screen-reader-text">What's Hapyning&reg;?</label>
          <TextareaAutosize name="content"
            value={content}
            required
            className="uk-input uk-margin-remove-bottom"
            placeholder="What's Hapyning&reg;?"
            onChange={updateInput}
            onKeyPress={submitOnEnter}
            onFocus={setFocus}/>
        </div>
      </div>
    </div>
  );
}
