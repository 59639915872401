import React from 'react';
import ResourceForm from './resources/Form';

import { createRichText, createResource, createGallery, createLinkCollection, createFileLibrary } from '../api';
import Turbolinks from 'turbolinks';

const NEW_RESOURCE = {
  title: '',
  preview_content: '',
  attachable: null,
};

const createAttachable = async (attachable, resource) => {
  switch (attachable.type) {
    case "RichText":
      const {preview_content, title} = resource;
      const {rich_text} = await createRichText({
        preview_content,
        preview_image: attachable.preview_image,
        content: attachable.content,
        title,
      });
      return rich_text.id;
    case "Gallery":
      const gallery = await createGallery(attachable.images);
      return gallery.id;
    case "FileLibrary":
      const fileLibrary = await createFileLibrary(attachable.files);
      return fileLibrary.id;
    case "LinkCollection":
      const linkCollection = await createLinkCollection(attachable);
      return linkCollection.id;
  }
};

export default class NewResource extends React.Component {
  createResource = async (resource) => {
    const {
      title,
      preview_content,
      attachable
    } = resource;

    const attachable_id = attachable.id || await createAttachable(attachable, resource);
    const params = {
      title,
      preview_content,
      hapyning_id: this.props.hapyning_id,
      resource_attachment_attributes: {
        attachable_id,
        attachable_type: attachable.type
      }
    };

    return new Promise((resolve, reject) => {
      createResource(params)
        .done(() => resolve(Turbolinks.visit(this.props.referring_path)))
        .fail((error) => reject(error))
    });
  }

  render() {
    return (
      <ResourceForm
        title="New Resource"
        hapyningId={this.props.hapyning_id}
        save={this.createResource}
        resource={NEW_RESOURCE}
      />
    );
  }
}
