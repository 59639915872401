import React from 'react';
import HapyningThumbnail from './HapyningThumbnail';
class EmailPreferences extends React.Component {
  state = {
    email_updates: this.props.email_updates,
    profiles: this.props.profiles
  }

  toggle = (name) => (event) => {
    $.ajax({
      url: "/api/v1/user_settings",
      method: "patch",
      data: { [name]: !this.state[name] }
    });
    this.setState((state) => ({ [name]: !state[name] }));
  }

  toggleHapyningProfile = (id, prop) => (e) => {
    this.setState(({ profiles }) => {
      return {
        profiles: _.map(profiles, (profile) => {
          if (profile.id === id) {
            $.ajax({
              url: `/api/v1/memberships/${id}`,
              method: "patch",
              data: { [prop]: !profile[prop] }
            });

            return { ...profile, [prop]: !profile[prop] };
          }

          return profile;
        })
      };
    });
  }

  render() {
    const { email_updates, profiles } = this.state;
    <div className="email-preferences-group"></div>
    return (
      <div className="email-preferences-group">
          <div className="checkbox-toggle uk-form-controls">
            <input
              type="checkbox"
              id="user_daily_updates"
              checked={email_updates}
              onChange={this.toggle("email_updates")} />
            <label className="label-first" htmlFor="user_daily_updates">
              Hapyn<span className="trademark-sign">&reg;</span> Email Updates
            </label>
          </div>
          {email_updates && (
            <div className="email-preferences-hapynings">
              <h2 className="email-preferences-subheading">Include Hapynings<span className="trademark-sign">&reg;</span></h2>
              {_.map(profiles, ({ id, hapyning_name, hapyning_image, include_in_email_digest }) => (
                <div key={id} className="checkbox-toggle uk-form-controls email-preferences-hapyning">
                  <HapyningThumbnail thumbnail={hapyning_image} />
                  <input
                    type="checkbox"
                    id={`include-in-digest-${id}`}
                    checked={include_in_email_digest}
                    onChange={this.toggleHapyningProfile(id, "include_in_email_digest")} />
                  <label className="label-first" htmlFor={`include-in-digest-${id}`}>
                    {hapyning_name}
                  </label>
                </div>
              ))}
            </div>
          )}
        <p className="disclaimer">Please note: you will still receive important administrative emails, such as password resets.</p>
      </div>
    );
  }
}

export default EmailPreferences;
