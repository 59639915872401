import UIkit from "uikit";

$(document).on('click', '[data-copy-text]', function(){
  navigator.clipboard.writeText($(this).data('copy-text')).then(function() {
    UIkit.notification('Copied to Clipboard!', 'success');
  });
});

$(document).on('click', '#password-reset', function(){
  UIkit.notification('An email has been sent to reset your password.', 'success');
});
