jQuery(function($) {
  $.initialize(".alert-modal", function() {
    var $modal = $(this);
    $modal.find(".alert-modal-action").prop('disabled', true);
    $modal.find(".alert-modal-action").addClass('disabled');

    $modal.on("hide", function(e) {
      $("#delete-confirm").prop('value', '');
    });
  });

  $(document).on('keyup', '.alert-modal .uk-input', function() {
    const $modal = $(this).closest('.alert-modal');
    var $input = $('#delete-confirm');
    if ($input.prop('value').toLowerCase() === "delete") {
      $modal.find('.alert-modal-action').prop('disabled', false);
      $modal.find(".alert-modal-action").removeClass('disabled');
    } else {
      $modal.find(".alert-modal-action").prop('disabled', true);
      $modal.find(".alert-modal-action").addClass('disabled');
    }
  });
});
