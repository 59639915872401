import React from 'react';
import { Icon } from '@iconify/react';
import fileIcon from '@iconify-icons/mdi/file';
import MaterialIcon from '../shared/MaterialIcon';

const FileLibraryField = ({fileLibrary, initiateUpload, removeFileFromLibrary}) => (
  <div style={{display: 'contents'}}>
    {_.map(fileLibrary.files, (file, index) => (
       <div className="uploaded-file uk-flex uk-flex-column">
       <Icon className="file-icon" icon={fileIcon} />
       <span className="uk-text-break">
         {file.identifier}
       </span>
       <button onClick={() => removeFileFromLibrary(file, index)} className="attachment-button cancel" aria-label="remove this attachment">
         <MaterialIcon name="clear" />
       </button>
     </div>
    ))}
  </div>
);

export default FileLibraryField;
