import { v1 as uuid } from "uuid";
import React from "react";
import { preventDefault } from "./helpers/events";
import { LocalTime } from "./helpers/time";
import ErrorBoundary from "./helpers/error_boundary";
import { updatePost, deletePost, deleteFileAsset, getUser, reportPost } from "../api";
import { upsertGallery, upsertFileLibrary } from "./helpers/attachable";
import MoreOptions from "./shared/MoreOptions";
import UserProfileModal from "./UserProfileModal";
import AlertModal from "./AlertModal";
import Avatar from "./Avatar";
import Form from "./posts/Form";
import PostContent from "./PostContent";
import Comments from "./Comments";
import Extras from "./Extras";
import classnames from "classnames";
import * as constants from "../constants";
import * as api from "../api";
import PostResourceCard from "./PostResourceCard";
import UIKit from "uikit";
import Alert from '@mui/material/Alert';

const toggleUkModal = (id) =>
  UIKit.toggle(document.getElementById(id)).toggle();
export default class
 extends React.Component {
  state = {
    ...this.props,
    editMode: false,
    galleryFlag: false,
    user: {
      full_name: "",
      screen_name: "",
      about: "",
    },
    alert: false,
    message: "",
    type: "success"
  };
  userProfileModalId = uuid();
  edit = () => this.setState({ editMode: true });
  cancelEdit = () => this.setState({ editMode: false });

  constructor(props) {
    super(props)
    this.myRef = React.createRef()
  }
  
  updatePost = async ({
    content,
    attachable,
    previousAttachable,
    preview: link_preview,
    ...postState
  }) => {
     const post = {
      id: this.props.post.id,
      content,
      link_preview,
      attachable_id: _.get(attachable, "id"),
      attachable_type: _.get(attachable, "type"),
      galleryFlag: this.state.galleryFlag
    };
    this.setState(({ post: postState }) => ({
      post: { ...postState, ...post, attachable },
      editMode: false,
      uploading: true,
    }));
    switch (_.get(attachable, "type")) {
      case "Gallery":
        post["attachable_id"] = await upsertGallery(
          this.state.post.id,
          attachable,
          postState.removedImages
        );
        break;
      case "FileLibrary":
        post["attachable_id"] = await upsertFileLibrary(
          post.id,
          attachable,
          postState.removedLibraryFiles
        );
        break;
    }

    if (!post.attachable_id) delete post.attachable_type;

    return updatePost(post).done((post) => {
      this.setState({ post, uploading: false, galleryFlag: false });
      // _.each(postState.removedFiles, ({ id }) => id && deleteFileAsset(id));
    });
  };

  deletePost = () => {
    const { post} = this.props
    deletePost(post.id).done(() =>{
      $("#postsFeed").find(`#feedPost-${post.id}`).remove();
    }
    );
  };


  reportPost = () => {
    const { post} = this.props
    reportPost(post.id).done(() => {
      this.setAlert('This post has been reported.', 'success');

    })
  }

  blockPostAuthor = (
    blockedById,
    blockedToId,
    blockedToFullName
  ) => {
    api.blockUser(blockedById, blockedToId)
      .done(() => {
        const key = constants.BLOCKED_USER_SESSION_KEY;
        sessionStorage.setItem(key, blockedToFullName);
        const message = `User ${blockedToFullName} is now blocked`;
        this.setAlert(message,'success');
        window.location = `/`;
      })
      .fail(() => {
        const message = `Failed to block ${blockedToFullName}`;
        this.setAlert(message, 'error');
      });
  }

  setAlert = (message, type) => {
    this.setState({alert: true, message: message, type: type});
    setTimeout(() => {
      this.setState({alert: false});
         }, 5000);
  }

  openProfileModal = async () => {
    const { post } = this.state;
    const post_user = await getUser(post.author_id);
    this.setState({ user: post_user });
    toggleUkModal(this.userProfileModalId);
  };

  setFlag = () => {
    this.setState({ galleryFlag: !this.state.galleryFlag})
  }

  render() {
    const { current_user, avatar, can_delete, hapyning, full_page_view, is_member, hapyning_icon, hapyning_parent } = this.props;
    const { editMode, post, uploading, user, alert, type, message } = this.state;

    const isEditedPost = post.updated_at > post.created_at;
    const canEdit = current_user && post.author_id === current_user.id;
    const canBlockPostAuthor = current_user && post.author_id !== current_user.id;
    const postId = `feedPost-${post.id}`;
    const alertModalId = `post-${post.id}-alert-modal`;
    const reportModalId = `report-${post.id}-alert-modal`
    const blockPostAuthorModalId = `block-post-${post.id}-alert-modal`;
    const showMoreOptions = canEdit || can_delete || is_member;

    if (editMode)
      return (
      <div id={postId} className="post uk-card uk-card-default">
          <Form
            hapyningId={hapyning.id}
            editing
            avatar={avatar}
            initialContent={post.content}
            initialLinkPreview={post.link_preview}
            onCancel={this.cancelEdit}
            onSave={this.updatePost}
            post={post}
            canEdit = {canEdit}
            setFlag = {this.setFlag}
          />
        </div>
      );
    return (
      <ErrorBoundary>
        {alert && ( <Alert severity={type} className="fixedAlert" onClose={() => {this.setState({alert: false});}}>{message}</Alert> )}
        <div
          id={postId}
          ref={this.myRef}
          className={classnames("post uk-card uk-card-default", {
            "former-member": post.former_member,
          })}
        >
          <div className="post-header uk-card-header">
            {showMoreOptions && (
              <MoreOptions title="More Options" dotClasses="uk-float-right">
                {canEdit && (
                  <li>
                    <a href="#" onClick={preventDefault(this.edit)}>
                      Edit Post
                    </a>
                  </li>
                )}
                {can_delete && (
                  <li>
                    <a href="#" uk-toggle={`target: #${alertModalId}`}>
                      Delete Post
                    </a>
                    <AlertModal
                      title="Confirm Delete Post"
                      bodyText="Are you sure you want to delete this post? This cannot be undone."
                      actionText="Delete Post"
                      action={this.deletePost}
                      elementId={alertModalId}
                    />
                  </li>
                )}
                {is_member && (
                  <li>
                     <a href="#" uk-toggle={`target: #${reportModalId}`}>
                      Report Post
                    </a>
                    <AlertModal
                      title="Report Post"
                      bodyText="Confirm you want to report this post to the admins"
                      actionText="Report Post"
                      action={this.reportPost}
                      elementId={reportModalId}
                    />
                  </li>
                )}
                {canBlockPostAuthor && (
                  <li>
                    <a href="#" uk-toggle={`target: #${blockPostAuthorModalId}`}>
                      Block Post Author
                    </a>
                    <AlertModal
                      title="Confirm Block Member"
                      bodyText="You will be able to unblock this member from your account settings at any time"
                      actionText="Block Member"
                      action={() => this.blockPostAuthor(current_user.id, post.author_id, post.full_name)}
                      elementId={blockPostAuthorModalId}
                    />
                  </li>
                )}
              </MoreOptions>
            )}
            <div className="post-header-details">
              <div className="parent">
              <img src={hapyning_icon} width={48} height={48} className={"image-below"}/>
              <a onClick={preventDefault(this.openProfileModal)}>
              <Avatar avatar={post.avatar} size={35} className={"image-above"}/>
              </a>
              </div>
              <div className="post-author-details">
              <a href={`/hapyning/${hapyning.slug}`} >
                <h5 className="uk-text-secondary text-semi-bold margin-bottom2px" >
                  {hapyning.name}
                </h5>
              </a>
              {hapyning.parent_id && <a href={`/hapyning/${hapyning_parent.slug}`} >
                <h5 className="uk-text-meta margin-bottom2px" >
                in <span className="uk-text-bolder">{hapyning_parent.name}</span>
                </h5>
              </a>}
                <a href="#" onClick={preventDefault(this.openProfileModal)}>
                  <p className="uk-text-meta uk-card-title ">
                    {post.full_name}
                <span className="uk-text-muted margin-left9px">
                  <LocalTime time={post.created_at} format="long" />
                </span>
                  </p>
                </a>
              </div>
            </div>
          </div>

          <div className="post-body uk-card-body post-content-container">
            <PostContent content={post.content} usePreview={!full_page_view} />
          </div>
          <PostResourceCard post={post} />

          {uploading ? (
            <div
              className="uk-position-center"
              data-uk-spinner={"ratio: 1"}
              aria-label="uploading"
            ></div>
          ) : (
            <Extras post={post} />
          )}

          {isEditedPost && (
            <div className="uk-text-meta post-edited-date">
              Edited <LocalTime time={post.updated_at} format="long" />
            </div>
          )}
          <Comments {...this.props} />
          <UserProfileModal
            bodyText="Do you want to leave before saving changes?"
            actionText="Abandon Changes"
            avatar={post.avatar}
            user={user}
            elementId={this.userProfileModalId}
          />
        </div>
      </ErrorBoundary>
    );
  }
}
