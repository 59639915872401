import React from 'react';
import Preview from '../richTexts/Preview';

import FileLibraryField from "../fields/FileLibraryField";
import GalleryField from "../fields/GalleryField";
import VideoField from "../fields/VideoField";
import FileAssetField from "../fields/FileAssetField";
import LinkCollectionField from '../fields/LinkCollectionField';

const AttachmentField = ({ attachable_type, attachable, removeFile, initiateUpload, removeImage, removeFileFromLibrary, updateLinkCollection, editing, canEdit, linkUpload }) => {
  switch (attachable_type) {
    case "RichText":
      return <Preview rich_text={attachable} removeFile={removeFile} canEdit={canEdit} />;
    case "FileLibrary":
      return <FileLibraryField
        fileLibrary={attachable}
        initiateUpload={initiateUpload}
        removeFileFromLibrary={removeFileFromLibrary} />;

    case "FileAsset":
      if (_.invoke(attachable, 'content_type.includes', 'video')) {
        return <VideoField video={attachable} removeFile={removeFile} />
      } else {
        return <FileAssetField fileAsset={attachable} removeFile={removeFile} />;
      }
    case "Gallery":
      return <GalleryField
        gallery={attachable}
        initiateUpload={initiateUpload}
        removeImage={removeImage} />;
    case "LinkCollection":
      const updateLinks = (modify) => updateLinkCollection(modify(attachable));
      return <LinkCollectionField attachable={attachable} setAttachable={updateLinks} editing={editing} linkUpload={linkUpload} />;
    default:
      return null;
  }
};

export default AttachmentField;
