$(document).on('click', '.reset-invitation-link', function() {
  const id = $(this).data("hapyning-id");
  $.ajax(`/hapynings/${id}`, {
    type: 'patch',
    contentType: 'application/json',
    data: JSON.stringify({invite_hash: ""})
  });

  Turbolinks.clearCache();
});
