import React from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import { isMobile } from 'react-device-detect';
import { stopEverything } from '../helpers/events';
import Avatar from '../Avatar';

const NEW_FORM_CLASSNAME = "post-comment-new";

class CommentForm extends React.Component {
  state = {
    ...this.props.initialState,
    saving: false,
    initialContent: _.get(this.props, 'initialState.content', '')
  }

  valid = () => !_.isEmpty(_.trim(this.state.content))

  createComment = () => {
    if (this.valid()) {
      this.setState({saving: true});
      const comment = {post_id: this.props.postId, content: this.state.content};
      return $.post('/comments', {comment}).done((response) => {
        if (response.id) {
          this.props.save(response);
          this.setState({saving: false});
          this.setState({content: ''});
        }
      });
    }
  }

  updateComment = () => {
    if (this.valid()) {
      this.setState({saving: true});
      const {postId, save} = this.props;
      const {content, id} = this.state;
      const comment = {post_id: postId, content, id};

      $.ajax({url: `/comments/${id}`, data: {comment}, type: 'PATCH'})
      .done((response) => {
        if (response.id) {
          this.setState({saving: false});
          this.setState({content: ''}, () => {
            save(response);
          });
        }
      })
      .fail(() => {
        console.error("Failed to update Comment");
        this.setState({saving: false});
      });
    }
  }

  submitOnEnter = (e) => {
    const onlyEnterPressed = e.key === 'Enter' && !e.shiftKey;
    if (onlyEnterPressed && !isMobile) {
      e.preventDefault();

      if (this.state.id ) {
        this.updateComment();
      } else {
        this.createComment();
      }
    }
  }

  updateInput = ({target: {value}}) => this.setState({content: value})

  render() {
    const {
      contentRef, avatar, className=NEW_FORM_CLASSNAME, placeholder, markCommentEditable
    } = this.props;
    const {content, id, initialContent, saving} = this.state;
    const commentUnchanged = id && (_.trim(content) === initialContent);

    return (
      <div className={className}>
        <Avatar avatar={avatar} className="post-comment-author"/>
        <div className="field">
          <div className="uk-form-controls">
            <label htmlFor="content" className="screen-reader-text">Comment</label>
            <TextareaAutosize name="content"
              ref={contentRef}
              value={content}
              required
              className="uk-input uk-margin-remove-bottom"
              placeholder={placeholder}
              onChange={this.updateInput}
              onKeyPress={this.submitOnEnter}/>
          </div>
        </div>
        {id ? (
          <div className="post-comment-actions">
            <button type="submit"
              disabled={!this.valid() || saving || commentUnchanged}
              className="uk-button uk-button-primary"
              onClick={stopEverything(saving ? _.noop : this.updateComment)}
            >
              {saving ? "Saving" : "Save"}
            </button>
            <button
              className="uk-button uk-button-secondary"
              onClick={markCommentEditable(id, false)}
              hidden={saving}
            >
              Cancel
            </button>
          </div>
        ) : (
          <div className="post-comment-actions">
            <button type="submit"
              disabled={!this.valid() || saving}
              className="comment-tag uk-button uk-button-primary"
              onClick={stopEverything(saving ? _.noop : this.createComment)}
            >
              {saving ? "Saving" : "Post"}
            </button>
        </div>
        )}
      </div>
    );
  }
}

export default CommentForm;
