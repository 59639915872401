import React from 'react';

export default function AlertModal(props) {
  const {
    title, bodyText, actionText, action, elementId
  } = props;

  return (
    <div id={elementId} className="alert-modal uk-flex-top uk-modal" data-uk-modal>
      <div className="uk-modal-dialog uk-text-center">
        <div className="uk-modal-header">
          <h3 className="alert">Alert</h3>
          {title && <h2 className="title">{title}</h2>}
        </div>
        <div className="uk-modal-body uk-text-left">
          {title ? bodyText : <h3>{bodyText}</h3>}
        </div>
        <div className="uk-modal-footer uk-flex uk-flex-column">
          <button className="uk-button uk-button-primary uk-modal-close" type="button" onClick={action}>{actionText}</button>
          <button className="uk-button uk-button-default uk-modal-close" type="button">Cancel</button>
        </div>
      </div>
    </div>
  );
}
