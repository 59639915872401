import React from "react";
import Avatar from "./Avatar";

export default function UserProfileModal(props) {
  const { avatar, elementId, user } = props;

  return (
    <div id={elementId} className="uk-flex-center uk-modal" data-uk-modal>
      <div className="uk-modal-dialog">
        <div className="user-profile-modal">
          <div>
            <h4>Member Profile</h4>
          </div>
          <div>
            <button
              className="uk-modal-close-default"
              type="button"
              uk-close="true"
            ></button>
          </div>
        </div>
        <div className="uk-modal-footer uk-flex uk-flex-column">
          <div className="profile-info">
            <div className="profile-avatar">
              <Avatar avatar={avatar} size={120} />
            </div>
            <div className="profile-text">
              <div className="profile-name">
                <h3>{user.full_name}</h3>
                <h5>{user.screen_name}</h5>
              </div>
              <div className="profile-description">
                <p>{jQuery(user.about).text()}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
