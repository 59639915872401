import React from 'react';
import { Header } from '../helpers/header';
import NoContentAvailable from '../posts/NoContentAvailable';
import MaterialIcon from '../shared/MaterialIcon';

const RichTextPreview = ({
  resource,
  removeFile,
  canEdit,
  rich_text: {
    title = '', created_at, updated_at, content, preview_content, preview_image, hapyning_rich_text_path, deleted_at
  }={}
}) => (
  <>
    <div className={resource ? "" : "post-article-preview mt-20"}>
      {deleted_at ? <NoContentAvailable /> :
        <div className="uk-flex uk-flex-column">
          <Header title={title} updated_at={updated_at} created_at={created_at}/>
          <p dangerouslySetInnerHTML={{__html: preview_content}}/>
          <div className="uk-text-center post-article-link">
            <a href={hapyning_rich_text_path}>Continue Reading</a>
          </div>
          {preview_image && <img src={preview_image} alt="" />}
          {canEdit && 
            <button onClick={removeFile} className="attachment-button rich-text-remove-button" aria-label="remove this rich text">
              <MaterialIcon name="clear"/>
            </button>
          }
        </div>
      }
    </div>
  </>
);

export default RichTextPreview;
