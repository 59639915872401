import React from 'react';

export const customLabels = {
  second: {
    past: {
      one: "just now",
      other: "just now"
    }
  },
  minute: {
    past: {
      one: "{0}m",
      other: "{0}m"
    }
  },
  hour: {
    past: {
      one: "{0}h",
      other: "{0}h"
    }
  },
  day: {
    past: {
      one: "{0}d",
      other: "{0}d"
    }
  },
  week: {
    past: {
      one: "{0}w",
      other: "{0}w"
    }
  },
  year: {
    past: {
      one: "{0}y",
      other: "{0}y"
    }
  },
}

export const customStyle = {
  steps: [
		{ formatAs: 'now' },
		{ formatAs: 'minute' },
		{ formatAs: 'hour' },
		{ formatAs: 'day' },
		{ formatAs: 'week' },
		{ formatAs: 'year' }
	],
  labels: 'custom'
}

const TIME_FORMATS = {
  short_date: "%-m/%-d/%Y",
  long: "%m/%d/%y %I:%M%P"
};

export function LocalTime({time, format}) {
  format = TIME_FORMATS[format] || format;
  return (
    <time key={_.uniqueId()} data-format={format} data-local="time" dateTime={time}>
      {time}
    </time>
  );
}
