import React from 'react';
import RichTextEditor from '../RichTextEditor';
import { Widget } from "@uploadcare/react-widget";

const backgroundImage = (url) => url ? ({backgroundImage: `url(${url})`}) : {};
export default class RichTextForm extends React.Component {
  attachPreviewImage = async (file) => {
    const {changeUploadCount, setAttachable} = this.props;
    file.type = file.mimeType;

    if (file.type == "image/heic") {
      file.originalUrl = file.originalUrl.concat("-/crop/959x960/240,0/-/preview/300x300".toString());
    }

    if (file.type.startsWith("image/")) {
      changeUploadCount(1);
      try {
        setAttachable({preview_image: file.originalUrl});
        changeUploadCount(-1);
      } catch (error) {
        setAttachable({error});
        changeUploadCount(-1);
      }
    }
  }

  render() {
    const { attachable, setAttachable, changeUploadCount } = this.props;

    return <>
      {attachable?.error && <div className="uk-alert uk-alert-danger">{attachable.error}</div>}
      <div className="field preview-button-container">
        <label>
          <div className="upload-button upload-button-margin" style={backgroundImage(attachable.preview_image)}>
          </div>
          <div>
            <p className="upload-photo-text">Preview Photo</p>
          </div>
          <p>
            <Widget
              id='file'
              name='file'
              publicKey= {process.env.UPLOADCARE_PUBLIC_KEY}
              previewStep='true'
              imagesOnly= 'true'
              onFileSelect={(file) => {
                if (file) {
                  file.done((info) => this.attachPreviewImage(info))
                }
              }}
            />
          </p>
        </label>
      </div>
      <RichTextEditor content={attachable.content}
        onUpdate={(content) => setAttachable({ content })}
        setUploadState={changeUploadCount} />
    </>;
  }
}
