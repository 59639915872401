import React from 'react';
import CloseIcon from 'cancel-icon-gray.svg';

const FileUploadField = ({fileLibrary, initiateUpload, removeFileFromLibrary}) => (
  <>
    <div className="file-upload-field uk-flex uk-flex-row uk-flex-wrap">
      {_.map(fileLibrary.files, (file, index) => (
        <span className="pill" key={file.id || _.uniqueId("file-")}>
            <span className="identifier">{file.identifier}</span>
            <img src={CloseIcon} className="cancel" onClick={() => removeFileFromLibrary(file, index)}/>
        </span>
      ))}
    </div>
  </>
);

export default FileUploadField;
