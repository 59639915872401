import React from 'react';

import progressImage from '../../../assets/images/work-in-progress.svg';

const VideoUploadPlaceholder = ({cancelUpload}) => {
  const cancelProgress = (e) => {
      e.preventDefault();
      cancelUpload(e);
  }
  return (
    <div className="video-encoding-message uk-flex uk-flex-column uk-flex-middle">
      <h3 className="uk-margin-remove-bottom">Processing Video</h3>
      <img className="video-encoding-image" src={progressImage} alt="work in progress"/>
      <p>We are processing your video.  This may take a few moments depending on the size of your video.</p>
      <p>When this is finished, you will be able to publish your post.</p>
      <button className="cancel-button uk-flex-stretch uk-button uk-button-primary" onClick={cancelProgress}>Cancel</button>
    </div>
  )
}

export default VideoUploadPlaceholder;
