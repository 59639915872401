import React from 'react';
import { Icon } from '@iconify/react';
import movieOpenPlay from '@iconify-icons/mdi/movie-open-play';

const VideoUploader = ({showUploadZone}) => {
  return <>
  <label className="uploader" data-upload-type="video" onClick={showUploadZone}>
    <Icon icon={movieOpenPlay} />
    <span className="screen-reader-text">Attach Video</span>
  </label>
  </>
}

export default VideoUploader;
