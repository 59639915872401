import React from 'react';
import RichTextEditor from '../RichTextEditor';
import FormStepTwo from './FormStepTwo';
import Preview from './Preview';
import TextareaAutosize from 'react-autosize-textarea';
import { stopEverything } from '../helpers/events';
import { getEventValue } from '../helpers/events';
import { RICH_TEXT_PREVIEW_TEXT_MAX as TEXT_LIMIT } from '../../constants';

const DEFAULT_ERROR_MESSAGE = 'An error occurred. Rich Text was not saved.';
const CONTAINER = document.createElement('DIV');
const getInnerText = (html) => {
  CONTAINER.innerHTML = html.replace('</p>', '</p>\n');
  return CONTAINER.innerText;
}
const NEW_RICH_TEXT = {content: "", title: "", preview_content: "", preview_image: ""};
const truncate = (lim, str) => getInnerText(str).slice(0, lim).trim();

export default class Form extends React.Component {
  state = {
    ...NEW_RICH_TEXT,
    ...this.props.rich_text || {},
    post_content: this.props.post_content,
    saving: false,
    uploadsInProgress: 0,
    step: 1,
    errorMessage: ""
  }
  updateField = _.curry((field, value) => this.setState({[field]: value}))
  updatePostContent = ({target: {value}}) => this.setState({post_content: value})

  nextPage = () => {
    switch (this.state.step) {
      case 1:
        return this.setState(({content, step, preview_content}) => {
          preview_content = preview_content || truncate(TEXT_LIMIT, content);
          return {
            characters: preview_content.length,
            preview_content,
            step: step + 1
          };
        });
      case 2:
        return this.setState(({content, step}) => {
          const customPreview = getInnerText(content).slice(0, TEXT_LIMIT).trim();
          return {
            characters: customPreview.length,
            step: step + 1
          };
        });
      case 3:
        return this.save();
    }
  }

  save = () => {
    this.setState({saving: true});
    this.props.save(this.state)
      .catch((response) => {
        const messagePath = 'responseJSON.error.message';
        const errorMessage = _.get(response, messagePath, DEFAULT_ERROR_MESSAGE);
        this.setState({saving: false, step: 1, errorMessage});
      });
  }

  goBack = () => this.setState({
    step: this.state.step - 1,
    errorMessage: ""
  });

  cancel = () => {
    if (_.isFunction(this.props.cancel)) {
      this.props.cancel();
    } else {
      window.history.back();
    }
  }

  setUploadState = (uploadChange) => this.setState(
    ({uploadsInProgress}) => ({uploadsInProgress: uploadsInProgress + uploadChange })
  );

  render() {
    const {
      title, content, preview_content, preview_image,
      saving, uploadsInProgress, step, errorMessage, post_content
    } = this.state;

    const saveText = this.props.hapyningId ? "Post" : "Save";
    const nextButtonText = (step < 3) ? "Next" : saveText;

    return (
      <div className="article-form">
        {step === 1 && <>
          <div>{errorMessage}</div>
          <div className="field">
            <label htmlFor="rich_text[title]">Title</label>
            <input type="text"
              className="uk-input"
              id="rich_text[title]"
              value={title}
              required
              autoFocus
              onChange={getEventValue(this.updateField("title"))} />
          </div>
          <div className="field">
            <label htmlFor="rich_text[content]">Content</label>
            <div className="uk-form-controls">
              <RichTextEditor content={content}
                field_name="rich_text[content]"
                onUpdate={this.updateField('content')}
                setUploadState={this.setUploadState} />
            </div>
          </div>
        </>}
        {step === 2 && (
          <FormStepTwo title={title}
            content={content}
            preview_content={preview_content}
            preview_image={preview_image}
            updateField={this.updateField} />
        )}
        {step === 3 && (
          <div>
            {this.props.hapyningId &&
              <div className="preview-post-message">
                <div className="post-article-preview">
                  <p htmlFor="rich_text[description]">Post Message <span className="field-info uk-float-right">Optional</span></p>
                  <TextareaAutosize
                    value={post_content}
                    onChange={this.updatePostContent}
                    className="uk-input uk-margin-remove-bottom"
                  />
                </div>
              </div>
            }
            <div>
              <Preview rich_text={this.state} />
            </div>
          </div>
        )}
        <div className="uk-text-right uk-margin uk-margin-top">
          {step === 1 && (
            <>
              {uploadsInProgress != 0 && <span className="upload-alert">Image uploading...</span>}
              <button
                className="uk-button uk-button-default"
                onClick={this.cancel}
              >
                Cancel
              </button>
            </>
          )}
          {(step === 2 || step === 3) && (
            <button
              className="uk-button uk-button-default"
              onClick={stopEverything(saving ? _.noop : this.goBack)}
              disabled={saving}
              >
                Back
              </button>
          )}
          <button type="submit"
            className="uk-button uk-button-primary"
            onClick={stopEverything(saving ? _.noop : this.nextPage)}
            disabled={!title || !content || uploadsInProgress}
          >
            {saving ? "Saving" : nextButtonText}
          </button>
        </div>
      </div>
    );
  }
}
