// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'core-js/stable'
import 'regenerator-runtime/runtime'

try{
  window._rollbarConfig = {};
  window._rollbarConfig = {
    accessToken: document.querySelectorAll('meta[name="rollbar-key"]')[0].content,
    captureUncaught: false,
    captureUnhandledRejections: false,
    payload: {
        environment: "production"
    }
  };
}
catch{
//pass
}

require("./rollbar");
require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("jquery");
require("jquery.initialize");
import UIKit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
UIKit.use(Icons);
window.UIKit = UIKit;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("./form_helpers");
require("./modal");
require("./alert_modal");
require("./nav");
require("./uploadcare");
require("./post");
require("./notifications");
require("./hapyning_settings");
require("./invitation");
require("./video");
require("./hide_resource_header");
require("./hapyning_header");
require("./scroll_behaviour");

import LocalTime from "local-time";
LocalTime.start();

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);


window.addEventListener('load', () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register('/service-worker.js').then(registration => {
      console.log('ServiceWorker registered: ', registration);

      var serviceWorker;
      if (registration.installing) {
        serviceWorker = registration.installing;
        console.log('Service worker installing.');
      } else if (registration.waiting) {
        serviceWorker = registration.waiting;
        console.log('Service worker installed & waiting.');
      } else if (registration.active) {
        serviceWorker = registration.active;
        console.log('Service worker active.');
      }

      window.Notification.requestPermission().then(permission => {
        if(permission !== 'granted'){
          throw new Error('Permission not granted for Notification');
        }
      });
    }).catch(registrationError => {
      console.log('Service worker registration failed: ', registrationError);
    });
  }
});


// force to relaod the page when internet connexion is offline to render the offline page in cache
window.addEventListener('offline', () => {
  window.location.reload();
});
window.addEventListener('beforeinstallprompt', (event) => {
  event.preventDefault();
  let deferredPrompt = event;
  let installButton = $('#install-button')[0];
  installButton.removeAttribute('hidden');
    $('#install-button').on('click', () => {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User installed app');
        } else {
          console.log('User did not install app');
        }
        deferredPrompt = null;
        installButton.setAttribute('hidden', '');
      });
    });

});
