import React from 'react';
import classnames from 'classnames';
import { createLinksForUrls } from './helpers/links';

export default class PostContent extends React.Component {
  state = {
    previewable: false,
    showAll: false
  }

  contentArea = React.createRef()

  componentDidMount() {
    if (this.props.usePreview) {
      this.resizeObserver = new ResizeObserver(this.checkContentPreviewable);
      this.resizeObserver.observe(this.contentArea.current);
    }
  }

  componentWillUnmount() {
    if (this.resizeObserver) this.resizeObserver.disconnect();
  }

  checkContentPreviewable = () => {
    const {scrollHeight, clientHeight} = this.contentArea.current;
    this.setState({previewable: scrollHeight > clientHeight});
  }

  toggleShowAll = () => this.setState(({showAll}) => ({showAll: !showAll}))

  render() {
    const {content, usePreview} = this.props;
    const {previewable, showAll} = this.state;
    const preview = usePreview && !showAll;

    return <>
      <div ref={this.contentArea}
        className={classnames("uk-clearfix post-content", {preview})}
        dangerouslySetInnerHTML={createLinksForUrls(content)}/>
        {usePreview && (
          previewable && !showAll ? (
            <a onClick={this.toggleShowAll} className="more-link">See More</a>
          ) : (
            showAll && <a onClick={this.toggleShowAll} className="less-link">See Less</a>
          )
        )}
    </>;
  }
}
