import heic2any from "heic2any";
import {
  createGallery, updateGallery, deleteGallery,
  createFileLibrary, updateFileLibrary, deleteFileLibrary
} from '../../api';

const noGalleryImages = ({ type, images = [] } = {}) => type === "Gallery" && !images.length;
const noLibraryFiles = ({ type, files = [] } = {}) => type === "FileLibrary" && !files.length;
const noLibraryVideos = ({ type, videos = null } = {}) => type === "VideoLibrary" && !videos;
const noLibraryEmbedUrls = ({ type, embed_urls = [""], videos = [] } = {}) => type === "VideoLibrary" && embed_urls[0] === "" && !videos.length;
const noLinkCollection = ({ newLink = false, type, links = [] } = {}) => !newLink && type === "LinkCollection" && !links.length;
const noRichText = ({ type } = {}) => type === "RichText"

export const noAttachablePresent = (attachable) => !attachable || noGalleryImages(attachable) || noLibraryFiles(attachable) || noLibraryVideos(attachable) || noLibraryEmbedUrls(attachable) || noLinkCollection(attachable)
export const noRichTextPresent = (attachable) => !attachable || noRichText(attachable)

export const attachablePresent = (attachable) => {
  const type = _.get(attachable, 'type');
  if (type === "Gallery") {
    const images = _.get(attachable, 'images', []);
    return images.length > 0;
  } else if (type === "FileLibrary") {
    const files = _.get(attachable, 'files', []);
    return files.length > 0;
  } else {
    return !_.isEmpty(attachable);
  }
};
export const validAttachable = (attachable) => {
  switch (_.get(attachable, 'type')) {
    case "RichText":
      return _.get(attachable, 'content.length') > 0;
    case "Gallery":
      return _.get(attachable, 'images.length') > 0;
    case "VideoLibrary":
      return _.get(attachable, 'videos.length') > 0 || (_.get(attachable, 'embed_urls.length') > 0 && _.get(attachable, 'embed_urls[0]') !== "");
    case "FileLibrary":
      return _.get(attachable, 'files.length') > 0;
    case "LinkCollection":
      return !_.some(attachable.links, 'editMode');
    default:
      return !_.isEmpty(attachable);
  }
}
const isUploadedFile = ({ file = {} } = {}) => file instanceof File;
const newImagesPresent = ({ images }) => _.some(images, isUploadedFile);
const newFilesPresent = ({ files }) => _.some(files, isUploadedFile);

export const upsertGallery = async (post_id = undefined, attachable, removedImages = []) => {
  const galleryIsModified = removedImages.length || newImagesPresent(attachable || {});
  if (!galleryIsModified) return _.get(attachable, 'id');

  if (!attachable.id) {
    const gallery = await createGallery(attachable.images);
    return gallery.id;
  }

  if (!attachable.images.length) {
    await deleteGallery(attachable.id);
    return;
  } else {
    await updateGallery(post_id, attachable);
    return attachable.id;
  }
};

export const upsertFileLibrary = async (post_id = undefined, attachable, removedLibraryFiles = []) => {
  const fileLibraryIsModified = removedLibraryFiles.length || newFilesPresent(attachable || {});
  if (!fileLibraryIsModified) return _.get(attachable, 'id');

  if (!attachable.id) {
    const fileLibrary = await createFileLibrary(attachable.files);
    return fileLibrary.id;
  }

  if (!attachable.files.length) {
    await deleteFileLibrary(attachable.id);
    return;
  } else {
    await updateFileLibrary(post_id, attachable);
    return attachable.id;
  }
};

export const addImageToGallery = (state, image) => {
  const attachableImages = _.get(state, "attachable.images", []);
  return {
    ...state,
    attachable: {
      id: _.get(state, "attachable.id"),
      type: "Gallery",
      images: [...attachableImages, image]
    },
    attachable_type: "Gallery",
    attachable_id: null,
    uploading: false,
    modified: true
  };
};

export const addFileToFileLibrary = (state, file) => {
  const attachableFiles = _.get(state, "attachable.files", []);
  return {
    ...state,
    attachable: {
      id: _.get(state, "attachable.id"),
      type: "FileLibrary",
      files: [...attachableFiles, file]
    },
    attachable_type: "FileLibrary",
    attachable_id: null,
    uploading: false,
    modified: true
  };
};

const convertHEIC = (file) => {
  if (file.type !== "image/heic") return file;
  return heic2any({
    blob: file,
    toType: "image/jpeg",
    quality: 1,
  }).then((conversionResult) => {
    const originalName = _.split(file.name, '.', 1);
    originalName[0] = originalName[0].concat(".jpeg".toString());
    return new File([conversionResult], originalName[0], { type: "image/jpeg" });
  })
    .catch((e) => {
      Rollbar.error("there was a problem with heic2any", e);
    });
}

export const prepareMedia = (file) => {
  const reader = new FileReader();
  return new Promise(async (resolve, reject) => {
    const convertedFile = await convertHEIC(file);
    if (convertedFile === undefined) {
      return reject("There was a problem uploading this image. Please reload the page and try again.")
    }
    reader.onload = ({ target }) => {
      resolve({
        url: target.result,
        content_type: convertedFile.type,
        file: convertedFile,
        identifier: convertedFile.name
      });
    };
    reader.readAsDataURL(convertedFile);
  });
};
