import React from 'react';
import DragAndDrop from '../shared/DragAndDrop';
import FileUploadField from '../fields/FileUploadField';

const FileForm = ({attachable, setAttachable}) => {
  const initiateUpload = async ({target}) => {
    _.each(target.files, (file) => {
      const newFile = {file, identifier: file.name};
      setAttachable((state) => ({files: [...(state.files ?? []), newFile]}));
    });
  };
  const removeFileFromLibrary = (file, index) => {
    setAttachable(({files=[], removedFiles=[]}) => {
      const removedFile = _.find(files, (removedFile, i) => removedFile === file && index === i);
      return {
        files: _.without(files, removedFile),
        removedFiles: [...removedFiles, removedFile]
      };
    });
  };
  return <>
  <div>
    <span className="file-upload-message">Other members can preview PDF files online. Other file types will need to be downloaded.</span>
    <DragAndDrop initiateUpload={initiateUpload}
                  accept='*'
                  uploadtype='file'>
      <div>
        <FileUploadField
          fileLibrary={attachable}
          initiateUpload={initiateUpload}
          removeFileFromLibrary={removeFileFromLibrary}
        />
      </div>
    </DragAndDrop>
  </div>
  </>
}

export default FileForm;
