import React from 'react';
import MaterialIcon from '../shared/MaterialIcon';

const FileAssetField = ({fileAsset, removeFile}) => (
  <div className="uploaded-file uk-flex uk-flex-column">
    <MaterialIcon className="file-icon" name="insert_drive_file" block/>
    <span className="uk-text-break">
      {fileAsset.identifier}
    </span>
    <button onClick={removeFile} className="attachment-button" aria-label="remove this attachment">
      <MaterialIcon name="clear"/>
    </button>
  </div>
);

export default FileAssetField;
