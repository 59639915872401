import React from 'react';

const AddImageField = ({initiateUpload}) => (
  <label className="add-image" data-upload-type="image">
    <span className={"material-icons plus-icon"} aria-hidden="true">add_circle_outline</span>
    <span className="screen-reader-text">Attach Image</span>
    <input type="file" accept="image/*, image/heic" onChange={initiateUpload} hidden multiple/>
  </label>
);

export default AddImageField;
