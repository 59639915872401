import React from 'react';

import ImageField from "../fields/ImageField";
import AddImageField from "../fields/AddImageField";

const GalleryField = ({gallery, removeImage, initiateUpload}) => (
  <>
    {gallery.images.map((image, index) => (
      <ImageField
        attachable_url={image.url}
        removeFile={() => removeImage(image, index)}
        key={"" + image.identifier + index}
      />
    ))}
  </>
);

export default GalleryField;
