const csrfToken = _.get(document.querySelectorAll('meta[name="csrf-token"]'), '[0].content');

export default {
  toolbar: {
    items: [
      "heading",
      "fontsize",
      "alignment",
      "|",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "imageUpload",
      "imageResize",
      "blockQuote",
    ],
  },
  simpleUpload: {
    uploadUrl: '/content_images',
    headers: {
      'X-CSRF-Token': csrfToken
    }
  },
  language: "en",
  image: {
    toolbar: [
      'imageTextAlternative',
      '|',
      'imageStyle:alignLeft',
      'imageStyle:full',
      'imageStyle:alignRight'
    ],
    styles: [
      'full',
      'alignLeft',
      'alignRight'
    ]
  },
  link: {
    addTargetToExternalLinks: true,
    defaultProtocol: 'https://'
  }
};
