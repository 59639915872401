import React from 'react';
import FileIcon from 'file-icon-primary.svg';
import Video from 'file-type-video@1.5x.svg'
import Pdf from 'file-type-pdf@1.5x.svg'
import Excel from 'file-type-excel@1.5x.svg'
import Word from 'file-type-word@1.5x.svg'
import Image from 'file-type-image@1.5x.svg'
import Zip from 'file-type-zip@1.5x.svg'
import PowerPoint from 'file-type-powerpoint@1.5x.svg'

function FileLibraryResource ({attachable}) {

  if (!attachable) return null;

  const {files} = attachable;

  const pickIcon = (contentType) => {
    const [type] = contentType.split(".").slice(-1).toLowerCase();
    switch ( type ) {
      case 'mov': case 'mp4':
        return Video
      case 'pdf':
        return Pdf
      case 'csv':
        return Excel
      case 'png': case 'jpg': case 'jpeg': case 'gif': case 'webp':
        return Image
      case 'zip':
        return Zip
      case 'ppt':
        return PowerPoint
      case 'doc': case 'docx':
        return Word
      default:
        return FileIcon
    }
  }

  return (
    <div className="resource-body">
      {_.map(files, ({id=_.uniqueId("file-"), url, identifier}) => (
        <span className="uk-flex uk-margin-top" key={id}>
          <img src={pickIcon(identifier)} aria-hidden="true"/>
          <a key={id} className="resource-file" href={url} download>{identifier}</a>
        </span>
      ))}
    </div>
  );
}

export default FileLibraryResource;
