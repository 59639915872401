import React from 'react';
import UploadcareImage from './UploadcareImage';
import DEFAULT_AVATAR from 'default-user.svg';

export default function Avatar(props) {
  const {avatar, size=32, ...attributes} = props;
  return (
    <UploadcareImage image={avatar}
      defaultImage={DEFAULT_AVATAR}
      width={size}
      height={size}
      circle
      {...attributes}/>
  );
}
