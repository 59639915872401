import React from 'react';
import { hostnameFromUrl } from '../helpers/links';
import { stopEverything } from '../helpers/events';
import MaterialIcon from '../shared/MaterialIcon';

const YOUTUBE_URLS = [
  "www.youtube.com",
  "youtu.be"
];

const youtube = (id) => `https://www.youtube.com/embed/${id}`;

const vimeo = (id) => `https://player.vimeo.com/video/${id}`;

const youtubeVideoId = (url) => {
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  if (match && match[2].length == 11) {
    return match[2];
  } else {
    return null;
  }
};

const vimeoVideoId = (url) => {
  const regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
  const match = url.match(regExp);
  return match[5];
};

const videoPlayer = (source) => <iframe src={source} className="embedded-video-player" height="300" frameBorder="0"></iframe>;
const isVideoFromYoutube = (domain) => _.includes(YOUTUBE_URLS, domain);

const getVideoTitle = (url) => {
  const domain = hostnameFromUrl(url);
  const youtubeVideo = isVideoFromYoutube(domain)
  let title = "";
  const videoUrl = youtubeVideo ? `https://noembed.com/embed?url=${url}` : `https://api.vimeo.com/videos/${vimeoVideoId(url)}`
  const headers = youtubeVideo ? {} : {"Authorization":`bearer ${process.env.VIMEO_PUBLIC_KEY}`}

  $.ajax({url: videoUrl, async: false, type: 'GET', headers: headers})
    .done((response) => {
      title = youtubeVideo ? JSON.parse(response).title : response.name;
    })
    .fail(() => {
    });
    return title;
}

export default class EmbeddedVideoPlayer extends React.Component {

  render() {
    const {url, removePreview} = this.props;
    const domain = hostnameFromUrl(url);
    const videoEmbedUrl = isVideoFromYoutube(domain) ?
        videoPlayer(youtube(youtubeVideoId(url))) :
        videoPlayer(vimeo(vimeoVideoId(url)));
    const videoTitle = getVideoTitle(url)

    return (
      <div className="embedded-video-container" id="frame">
        {removePreview &&
          <button
            type="button"
            onClick={stopEverything(removePreview)}
            title="Remove Preview"
            className="attachment-button"
            aria-label="remove this url preview"
          >
            <MaterialIcon name="clear"/>
          </button>
        }
        <div className="embedded-video">
          {videoEmbedUrl}
        </div>
        {videoTitle && <h5 className="embedded-video-title">{ videoTitle }</h5>}
        <h5 className="embedded-video-domain">{isVideoFromYoutube(domain) ? domain.slice(4) : domain}</h5>
      </div>
    )
  }
}
