import UIkit from "uikit";

jQuery(function($) {
  $(document).on('click', '.accept-button, .decline-button', function (event) {
    const {membershipId, hapyningId, active, declined} = $(this).data();
    const type = active ? 'patch' : 'delete';
    const $invitation = $(this).closest('.invitation-card');
    const lastInvitation = $('.invitation-card').length === 1;

    $.ajax(`/hapynings/${hapyningId}/memberships/${membershipId}`, {
      type,
      contentType: 'application/json',
      data: JSON.stringify({active, declined, invitations_index: true})
    })
    .always(function() {
      if (lastInvitation) {
        Turbolinks.visit(window.location.toString(), {action: 'replace'});
      }
      $invitation.remove();
    });
  });
});

$(document).on("click", "#copies", function () {
  let inputText = $("#hapyn-generic-invite")[0].value;
  navigator.clipboard.writeText(inputText).then(function () {
    UIkit.notification("Copied to Clipboard!", "success");
  });
});
