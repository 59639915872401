import React from 'react';

const MAX_IMAGE_DISPLAY_COUNT = 4;

const DOUBLE_ROW_POSITIONS = {
  MAIN: [1, 2],
  DEFAULT: [0, 1]
};

const TRIPLE_ROW_POSITIONS = {
  MAIN: [1, 2, 3],
  DEFAULT: [2, 3, 4]
};

const CountOverlay = ({images=[], countFrom, displayOverlay}) => {
  if (!displayOverlay) return null;

  const displayedImageCount = countFrom - 1;
  const maxDisplayCountExceeded = countFrom && displayedImageCount > MAX_IMAGE_DISPLAY_COUNT;
  const count = images.length - (maxDisplayCountExceeded ? MAX_IMAGE_DISPLAY_COUNT : displayedImageCount);

  return <>
    <div className="overlay uk-dark">
      <span className="uk-overlay-primary uk-position-cover uk-border-rounded">
        <span className="uk-position-center">+{count}</span>
      </span>
    </div>
  </>;
};

export const Image = ({openLightBox, image={}, overlay, classes}) => (
  <div className={`image ${classes}`}
    onClick={openLightBox.bind(this, image.position)}
    style={{background: `url(${image.url}) no-repeat center`}}
  >
    {!_.isEmpty(overlay) && overlay}
  </div>
);

export const SingleImage = ({openLightBox, image={}}) => (
  <>
    <img
    src= {image.url}
    onClick={openLightBox.bind(this, image.position)}
    className='single-image'
    ></img>
  </>
);

const isLastImageIn = (images, image) => _.isEqual(_.last(images), image);

const selectImageIndices = (condition, indices, defaultIndices) => (
  condition ? indices : defaultIndices
);

const setImagePositions = (images) => (indices) => _.map(indices, (position) => ({
  ...images[position],
  position
}));

const prepareForGridDisplay = (images=[], doubleRow) => {
  if (!images.length) return;

  const preparePositions = _.flow(selectImageIndices, setImagePositions(images));

  if (doubleRow) {
    return preparePositions(
      images.length === 3,
      DOUBLE_ROW_POSITIONS.MAIN,
      DOUBLE_ROW_POSITIONS.DEFAULT
    );
  } else {
    return preparePositions(
      images.length === 4,
      TRIPLE_ROW_POSITIONS.MAIN,
      TRIPLE_ROW_POSITIONS.DEFAULT
    );
  }
};

const Images = ({images=[], openLightBox, overlay, countFrom, doubleRow=false}) => {
  const preparedImages = prepareForGridDisplay(images, doubleRow);
  const classes = doubleRow ? "image-row double uk-width-1-2" : "image-row triple uk-width-1-3";

  return <>
    {_.map(preparedImages, (image) => {
      const displayOverlay = isLastImageIn(preparedImages, image) && overlay;

      return (
        <Image key={image.url + image.position}
          image={image}
          openLightBox={openLightBox}
          classes={classes}
          overlay={
            <CountOverlay images={images}
              displayOverlay={displayOverlay}
              countFrom={countFrom}
            />
          }
        />
      );
    })}
  </>;
};

export default Images;
