import React from 'react';
import GalleryField from '../fields/GalleryField';
import { prepareMedia } from '../helpers/attachable';
import DragAndDrop from '../shared/DragAndDrop';

const GalleryForm = ({attachable, setAttachable, changeUploadCount}) => {
  const initiateUpload = async ({target}) => {
    _.each(target.files, async (file) => {
      if (file.type.startsWith('image/')) {
        changeUploadCount(1);
        const image = await prepareMedia(file);
        setAttachable((state) => ({images: [...(state.images ?? []), image]}));
        changeUploadCount(-1);
      }
    });
  };
  const removeImageFromGallery = (image, index) => {
    setAttachable(({images=[], removedImages=[]}) => {
      const removedImage = _.find(images, (img, i) => img === image && index === i);
      return {
        images: _.without(images, removedImage),
        removedImages: [...removedImages, removedImage]
      };
    });
  };

  return <>
  <div>
    <DragAndDrop initiateUpload={initiateUpload}
                  uploadtype='image'
                  accept="image/*, image/heic">
      <div>
        <div className="uk-flex uk-flex-wrap">
          <GalleryField gallery={attachable}
            initiateUpload={initiateUpload}
            removeImage={removeImageFromGallery}/>
        </div>
      </div>
    </DragAndDrop>
  </div>
  </>;
}

export default GalleryForm;
