import React from 'react';

import RichTextPreview from './richTexts/Preview';
import EmbeddedVideoPlayer from './posts/EmbeddedVideoPlayer';
import FileAssetPreview from './posts/FileAssetPreview';
import FileLibraryPreview from './posts/FileLibraryPreview';
import LinkPreview from './previews/LinkPreview';
import GalleryImagesGrid from './galleryImagesGrid';
import VideoPreview from './VideoPreview';
import LinkCollectionPreview from './previews/LinkCollectionPreview';

const COUNT_FROM = 5;

export const WebLinkPreview = ({preview, removePreview}) => {
  if (_.isEmpty(preview) || preview.disable) return null;

  if (preview.video) return <EmbeddedVideoPlayer url={preview.video} removePreview={removePreview}/>;
  return <LinkPreview preview={preview} removePreview={removePreview}/>;
};

export default function Extras(props) {
  const {post: {attachable_type, attachable, link_preview, resource} = {}} = props;
  switch (attachable_type) {
    case "RichText":
      return <RichTextPreview rich_text={attachable} resource={resource}/>;
    case "FileAsset":
      return <FileAssetPreview attachment={attachable}/>;
    case "FileLibrary":
      return <FileLibraryPreview attachment={attachable}/>;
    case "Gallery":
      const images = _.get(attachable, 'images', []);

      return <GalleryImagesGrid images={images} countFrom={COUNT_FROM}/>;
    case "VideoLibrary":
      if (attachable.embed_urls[0] !== "" || !_.isEmpty(attachable.videos)) {
        return <VideoPreview attachment={attachable}/>;
      }
      else {
        return null
      }
    case "LinkCollection":
      return <LinkCollectionPreview attachment={attachable}/>;
    default:
      return <WebLinkPreview preview={link_preview}/>;
  }
}
