import React, { Component } from 'react';
import UIkit from "uikit";
import PropTypes from 'prop-types';
import Images, { SingleImage } from './grid_images';

const MAX_COUNT_FROM = 5;
const SINGLE_ROW_LENGTHS = [1, 3, 4];
const DOUBLE_ROW_OVERLAY_VALUES = [2, 3];
const TRIPLE_ROW_OVERLAY_VALUES = [4, 5];

class GalleryImagesGrid extends Component {
  static defaultProps = {
    images: [],
    countFrom: MAX_COUNT_FROM
  }

  constructor(props) {
    super(props)
    const validCountFrom = props.countFrom > 0 && props.countFrom < MAX_COUNT_FROM;
    const countFrom = validCountFrom ? props.countFrom : MAX_COUNT_FROM;

    this.state = {countFrom};

    if (props.countFrom <= 0 || props.countFrom > MAX_COUNT_FROM) {
      console.warn('countFrom is limited to 5!');
    }
  }

  openLightBox = (index) => {
    const {images} = this.props;
    const lightboxImages = _.map(images, ({url}) => ({source: url}));
    UIkit.lightboxPanel({items: lightboxImages}).show(index);
  }

  render() {
    const {countFrom} = this.state;
    const {images} = this.props;
    const imagesToShow = countFrom && images.length > countFrom ? countFrom : images.length;
    const doubleImageRow = imagesToShow >= 2 && imagesToShow != 4;
    const tripleImageRow = imagesToShow >= 4;

    return (
        <div className="gallery-images-grid uk-grid">
          {SINGLE_ROW_LENGTHS.includes(imagesToShow) && (
            <SingleImage image={{...images[0], position: 0}}
              openLightBox={this.openLightBox}
            />
          )}
          {doubleImageRow && (
            <Images images={images}
              openLightBox={this.openLightBox}
              overlay={images.length > countFrom && DOUBLE_ROW_OVERLAY_VALUES.includes(countFrom)}
              doubleRow={true}
              countFrom={countFrom}
            />
          )}
          {tripleImageRow && (
            <Images images={images}
              openLightBox={this.openLightBox}
              overlay={
                countFrom > MAX_COUNT_FROM ||
                images.length > countFrom && TRIPLE_ROW_OVERLAY_VALUES.includes(countFrom)
              }
              countFrom={countFrom}
            />
          )}
        </div>
    )
  }
}

GalleryImagesGrid.propTypes = {
  images: PropTypes.array.isRequired,
  countFrom: PropTypes.number
};

export default GalleryImagesGrid;
