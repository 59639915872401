import React from "react";
import VideoUploadPlaceholder from "../shared/VideoUploadPlaceholder";
import EmbeddedVideoPlayer from "../posts/EmbeddedVideoPlayer";
import MaterialIcon from "../shared/MaterialIcon";
import * as api from "../../api";
import DragAndDrop from "../shared/DragAndDrop";

const FILE_SIZE_LIMIT = 5.12e8;
const videoUrlPattern =
  /^(http:\/\/|https:\/\/)(vimeo\.com|youtu\.be|www\.youtube\.com)\/([\w\/]+)([\?].*)?$/;

export default class VideoForm extends React.Component {
  state = {
    videoURL: _.get(this.props.attachable, "embed_urls[0]"),
  };

  poll = (id) => {
    const { attachable, setAttachable } = this.props;

    if (attachable.id) {
      api.getVideoLibrary(id).then((videoLibrary) => {
        if (!videoLibrary.videos_processing) {
          setAttachable(videoLibrary);
        } else {
          setTimeout(this.poll, 2000, id);
        }
      });
    }
  };

  uploadComplete = (videoLibrary) => {
    this.props.setAttachable(videoLibrary);
    this.props.changeUploadCount(-1);
    if (videoLibrary.videos_processing) {
      this.poll(videoLibrary.id);
    }
  };
  createOrUpdate = (videos) => {
    if (
      this.props.attachable?.id &&
      this.props.attachable.type === "VideoLibrary"
    ) {
      return api.updateVideoLibrary({
        id: this.props.attachable.id,
        videos,
        embed_urls: null,
      });
    } else {
      return api.createVideoLibrary(videos);
    }
  };

  initiateUpload = async ({ target: { files } }) => {
    if (_.last(files).type.startsWith("video/")) {
      if (_.last(files).size < FILE_SIZE_LIMIT) {
        this.props.changeUploadCount(1);
        this.createOrUpdate(files)
          .done(this.uploadComplete)
          .fail(() => this.props.changeUploadCount(-1));
      } else {
        this.props.setAttachable({
          errorMessage:
            "Your video is too large.  Please select a video that is smaller than 512MB.",
        });
      }
    }
  };

  cancelUpload = () => {
    this.props.changeUploadCount(-1);
    this.props.setAttachable({ id: null, videos: null });
  };

  createOrDeleteLink = () => {
    const videos = [this.state.videoURL];
    if (this.props?.attachable?.id && _.isEmpty(this.state.videoURL)) {
      return api.deleteVideoLibraryEmbedUrls({
        id: this.props.attachable.id,
      });
    } else {
      return api.createVideoLibraryWithEmbedUrl(videos);
    }
  };
  removeVideoLink = () => {
    this.setState(
      {
        videoURL: "",
      },
      () => {
        this.addVideoLink(true);
      }
    );
  };
  addVideoLink = async (remove_url) => {
    if (videoUrlPattern.test(this.state.videoURL) || remove_url) {
      this.props.changeUploadCount(1);
      this.createOrDeleteLink()
        .then(this.uploadComplete)
        .catch(() => this.props.changeUploadCount(-1));
      this.setState({
        VideoError: false,
      });
    } else {
      this.setState({
        VideoError: true,
      });
    }
  };

  removeVideoLibrary = () => {
    // api.deleteVideoLibrary(this.props.attachable.id);
    this.setState({ videoURL: "" });
    this.props.setAttachable({ id: null, videos: null });
  };

  render() {
    const { attachable, uploadsInProgress, showDragDrop } = this.props;
    const embed_url = _.get(attachable, "embed_urls[0]");
    const videoSrc = _.get(attachable, "videos[0].url");

    return uploadsInProgress > 0 ? (
      <VideoUploadPlaceholder cancelUpload={this.cancelUpload} />
    ) : (
      <>
        <div>
          <DragAndDrop
            initiateUpload={this.initiateUpload}
            accept="video/*,.mp4, .mov, .avi, .wmv, .flv, .mkv"
            uploadtype="video"
          >
            {!embed_url && (
              <>
                {videoSrc && (
                  <div
                    className={
                      window?.location?.href?.includes("resources")
                        ? "video-preview-resource-container"
                        : "video-preview-post-container"
                    }
                  >
                    <video src={videoSrc} className="video-preview" controls />
                    <MaterialIcon
                      name="cancel"
                      className="cancel"
                      onClick={this.removeVideoLibrary}
                    />
                  </div>
                )}
              </>
            )}
          </DragAndDrop>
        </div>

        {!videoSrc && (
          <div className="edit-video-url">
            {!embed_url && <h4>Or Link to Video</h4>}
            <input
              name="video-url"
              type="video"
              placeholder="type or paste link here"
              className={`uk-input ${
                this.state.VideoError && "video-url-error"
              }`}
              value={this.state.videoURL}
              onChange={(e) => this.setState({ videoURL: e.target.value })}
            />
            <div className="video-error-message-container">
              {this.state.VideoError ? (
                <span className="video-error-message">
                  Please enter youtube or vimeo url
                </span>
              ) : (
                ""
              )}
            </div>
            <button
              className="uk-button uk-button-secondary"
              onClick={this.addVideoLink}
            >
              {embed_url ? "Change" : "Add"} Link
            </button>
            {embed_url && (
              <button
                className="remove-link-button uk-button uk-button-secondary"
                onClick={this.removeVideoLink}
              >
                Remove link
              </button>
            )}
            {embed_url && (
              <EmbeddedVideoPlayer url={embed_url} removePreview={false} />
            )}
          </div>
        )}
      </>
    );
  }
}
