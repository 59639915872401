import React from 'react';
import { RICH_TEXT_PREVIEW_TEXT_MAX as TEXT_LIMIT } from '../../constants';
import { getEventValue } from '../helpers/events';

const CONTAINER = document.createElement('DIV');
const getImages = (html) => {
  CONTAINER.innerHTML = html;
  return CONTAINER.getElementsByTagName("img");
};

export default class FormStepTwo extends React.Component {
  handleChange = (input) => {
    if (input.length <= TEXT_LIMIT) {
      this.props.updateField('preview_content', input);
    }
  }

  selectImage = (src) => () => this.props.updateField('preview_image', src);

  render() {
    const { preview_content, preview_image, title, content } = this.props;

    return (
      <>
        <div className="field">
          <p className="custom-preview">Customize Preview</p>
        </div>
        <div className="field">
          <div className="uk-form-controls">
            <h2 className="preview-heading">Title</h2>
            <div className="preview-title">{title}</div>
          </div>
        </div>
        <div className="field">
          <div className="uk-form-controls">
            <label htmlFor="rich_text[description]">Description</label>
            <div className="description-message">A preview message will only show in the Rich Text Preview, not in your Rich Text Page.</div>
            <div contentEditable='true'  id="rich_text[description]" className="preview-description uk-textarea" onBlur={(e) => this.handleChange(e.currentTarget.textContent)} dangerouslySetInnerHTML={{__html: preview_content}}/>
            <p className="field-info uk-float-right uk-margin-remove-top">{preview_content.length}/{TEXT_LIMIT}</p>
          </div>
        </div>
        <div className="field">
          <label htmlFor="rich_text[image]">Image</label>
          <div className="uk-form-controls uk-flex uk-flex-wrap uk-margin-top">
            {_.map(getImages(this.props.content), (image) => (
              <div className={`uploaded-image post-article-preview-image selectable-image ${preview_image == image.src ? "selected" : ""}`}
                key={image.src}
                onClick={this.selectImage(image.src)}
                style={{ backgroundImage: `url(${image.src})` }}>
                <button className="attachment-button default-btn" aria-label="select this image">
                  <span className="material-icons default">{preview_image == image.src ? "check_circle" : "radio_button_unchecked"}</span>
                </button>
              </div>
            ))}
            <div className={`uploaded-image post-article-preview-image no-image selectable-image ${preview_image == "" ? "selected" : ""}`}
              key="no-image"
              onClick={this.selectImage("")}>
              <span>No Image</span>
              <button className="attachment-button default-btn" aria-label="select no image">
                <span className="material-icons default">{preview_image == "" ? "check_circle" : "radio_button_unchecked"}</span>
              </button>
            </div>
          </div>
        </div>
      </>
    )
  }
}
