import React from 'react';
import { createPost, createGallery, createFileLibrary, createLinkCollection } from '../api';
import Form from './posts/Form';
import ErrorBoundary from './helpers/error_boundary';

export default class NewPost extends React.Component {
  state = {key: 0}

  reset = () => this.setState(({key}) => ({key: key + 1}))

  createPost = async ({content, attachable, attachable_type, preview}) => {
    switch (attachable_type) {
      case "Gallery":
        attachable = await createGallery(attachable.images);
        break;
      case "FileLibrary":
        attachable = await createFileLibrary(attachable.files);
        break;
      case "LinkCollection":
        attachable = await createLinkCollection(attachable);
        break;
      }

    const {hapyning_id, addNewPost} = this.props;
    const post = {
      hapyning_id,
      content,
      link_preview: preview,
      attachable_id: attachable ? attachable.id : null,
      attachable_type: attachable ? attachable.type : null
    };

    return createPost(post)
     .done(addNewPost).then(this.reset());
  }

  render() {
    const {avatar, hapyning_id} = this.props;

    return (
      <ErrorBoundary>
        <div className="post-form-new">
          <Form key={this.state.key}
            avatar={avatar}
            onCancel={this.reset}
            onSave={this.createPost}
            hapyningId={hapyning_id}
          />
        </div>
      </ErrorBoundary>
    );
  }
}
