const csrfToken = _.get(document.querySelectorAll('meta[name="csrf-token"]'), '[0].content');

const $postFormData = (options) => $.ajax({type: 'POST', contentType: false, processData: false, ...options});

const $patchFormData = (options) => $.ajax({type: 'PATCH', contentType: false, processData: false, ...options});

export function createFileAsset(file) {
  const data = new FormData();
  data.append("file_asset", file);

  return $postFormData({url: '/api/v1/file_assets', data});
}

const prepareMedia = ({file, identifier}) => {
  return file instanceof File ? file : identifier;
};

const prepareGalleryData = (images) => {
  const data = new FormData();
  _.each(images, (image) => {
    data.append("gallery[images][]", prepareMedia(image));
  });
  return data;
};

export function createGallery(images) {
  const data = prepareGalleryData(images);

  return $postFormData({url: '/api/v1/galleries', data});
}

const prepareFileLibraryData = (files) => {
  const data = new FormData();
  _.each(files, (file) => {
    data.append("file_library[files][]", prepareMedia(file));
  });
  return data;
};

export function createFileLibrary(files) {
  const data = prepareFileLibraryData(files);

  return $postFormData({url: '/api/v1/file_libraries', data});
}

export function updateFileLibrary(post_id, {id, files}) {
  const data = prepareFileLibraryData(files);
  if( post_id ) {
    data.append("post_id", post_id);
  }
  return $patchFormData({url: `/api/v1/file_libraries/${id}`, data});
}

export function deleteFileLibrary(id) {
  return $.ajax({
    url: `/api/v1/file_libraries/${id}`,
    type: 'DELETE'
  });
}

const prepareVideoLibraryData = (videos) => {
  const data = new FormData();
  _.each(videos, (video) => {
    data.append("video_library[videos][]", video);
  });
  data.append("video_library[embed_urls][]", []);
  return data;
};

export function createVideoLibraryWithEmbedUrl(videos) {
  const video_library = {embed_urls: videos};
  return $.post('/api/v1/video_libraries', {video_library})
}

export function createVideoLibrary(videos) {
  const data = prepareVideoLibraryData(videos);
  return $postFormData({url: '/api/v1/video_libraries', data});
}

export function getVideoLibrary(id) {
  return $.ajax({url: `/api/v1/video_libraries/${id}`, type: 'GET'});
}

export function deleteVideoLibraryEmbedUrls({id}) {
  return $.ajax({
    url: `/api/v1/video_libraries/${id}`,
    type: 'DELETE',
    data: {
      check: "true"
    }
  });
}

export function updateVideoLibrary({id, videos}) {
  const data = prepareVideoLibraryData(videos);
  return $patchFormData({url: `/api/v1/video_libraries/${id}`, data});
}

export function deleteVideoLibrary(id) {
  return $.ajax({
    url: `/api/v1/video_libraries/${id}`,
    type: 'DELETE'
  });
}

export function updateGallery(post_id, {id, images}) {
  const data = prepareGalleryData(images);
  if( post_id ) {
    data.append("post_id", post_id);
  }
  return $patchFormData({url: `/api/v1/galleries/${id}`, data});
}

export function deleteGallery(id) {
  return $.ajax({
    url: `/api/v1/galleries/${id}`,
    type: 'DELETE'
  });
}

export function deleteFileAsset(id) {
  return $.ajax({url: `/api/v1/file_assets/${id}`, type: 'DELETE'});
}

export function createPost(post) {
  return $.post('/api/v1/posts', {post});
}

export function updatePost({id, ...post}) {
  if (!post.attachable_id && !post.attachable_type) {
    post.attachable = {_remove: true};
  }
  return $.ajax({
    url: `/api/v1/posts/${id}`,
    data: {post},
    type: 'PATCH'
  });
}

export function deletePost(id) {
  return $.ajax({url: `/api/v1/posts/${id}`, type: 'DELETE'});
}

export function reportPost(id) {
  return $.ajax({url: `/api/v1/report_posts/${id}`, type: 'PATCH'});
}

export function createResource(resource) {
  return $.post('/api/v1/resources', {resource});
}

export function updateResource({id, ...resource}) {
  return $.ajax({
    url: `/api/v1/resources/${id}`,
    data: {resource},
    type: 'PATCH'
  });
}

export function createRichText(rich_text) {
  return $.post({url: '/api/v1/rich_texts', data: {rich_text}});
}

export function updateRichText({id, ...rich_text}) {
  return $.ajax({url: `/api/v1/rich_texts/${id}`, type: 'PATCH', data: {rich_text}});
}

export function deleteRichText(id) {
  return $.ajax({
    url: `/api/v1/rich_texts/${id}`,
    type: 'DELETE'
  });
}

export function createLinkCollection(link_collection) {
  return fetch('/api/v1/link_collections', {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    },
    method: 'POST',
    body: JSON.stringify({link_collection}),
    credentials: 'include'
  }).then((response) => response.json());
}

export function updateLinkCollection({id, links}, post_id) {
  return fetch(`/api/v1/link_collections/${id}`, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    },
    method: 'PATCH',
    body: JSON.stringify({links, post_id}),
    credentials: 'include'
  }).then((response) => response.json());
};

export function deleteLinkCollection(id) {
  return $.ajax({
    url: `/api/v1/link_collections/${id}`,
    type: 'DELETE'
  });
}

export function getFileAsset(id) {
  return $.ajax({url: `/api/v1/file_assets/${id}`, type: 'GET'});
}

export function fetchLinkPreview(url) {
  return fetch(`/api/v1/preview.json?url=${url}`)
    .then((response) => response.json());
}

export function createContentImage(file) {
  const data = new FormData();
  data.append("upload", file);

  return $postFormData({url: '/content_images', data});
}

export function getNewPosts(id, minutes,check = false) {
  return $.ajax({url: `/api/v1/posts/${id}`, type: 'GET', data: {minutes: minutes, check: check}});
}

export function getUser(id) {
  return $.ajax({url: `/api/v1/users/${id}`, type: 'GET'});
}

export function lazyLoadPosts(created_at, hapyning) {
  return $.ajax({url: `/lazy_load_posts`, type: 'GET', data: {created_at: created_at, id: hapyning}});
}

export function unblockUser(blockedById, blockedEntryId) {
  return $.ajax({
    url: `/api/v1/users/${blockedById}/unblock_user`,
    data: { blocked_user_id: blockedEntryId },
    type: "DELETE"
  });
}

export function blockUser(blockedById, blockedToId) {
  return $.ajax({
    url: `/api/v1/users/${blockedById}/block_user`,
    data: { blocked_to: blockedToId },
    type: "POST"
  });
}

export function getPostPerPage(page) {
  return $.ajax({
    method: 'GET',
    url: '/home.json',
    data: { page: page },
  });
}
