import React from 'react'
import { linksInContent } from '../helpers/links';

const formatLink = (url) => _.invoke(linksInContent(url), '0.getUrl');

export default class LinkForm extends React.Component {
  state = {
    id: this.props.id,
    url: this.props.url || "",
    title: this.props.title || "",
  }

  save = () => {
    const {id, url, title} = this.state;
    this.props.onSave({id, title, url: formatLink(url)});
  }

  render() {
    const {saveButtonLabel} = this.props;
    const {url, title} = this.state;

    return (
      <div className="link-attachment-container edit-link-form">
        <div className="uk-flex uk-flex-column uk-width-1-1">
          <label className="uk-flex uk-flex-column">Website URL
            <input
              name="link-url"
              type="text"
              placeholder="type or paste link here"
              value={url}
              onChange={(e) => this.setState({url: e.target.value})}
              className="uk-input"
            />
          </label>
          <label className="uk-flex uk-flex-column">Title
            <input
              name="link-title"
              type="text"
              value={title}
              onChange={(e) => this.setState({title: e.target.value})}
              className="uk-input"
            />
          </label>
        </div>
        <button
          className="uk-button uk-button-secondary"
          disabled={!formatLink(url)}
          onClick={this.save}>{saveButtonLabel}</button>
      </div>
    );
  }
}
