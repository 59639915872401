import React from "react";
import PostCard from "./PostCard";
import NewPost from "./NewPost";
import EmptyPosts from "./posts/EmptyPosts";
import consumer from "../channels/consumer";
import * as api from "../api";
import { browserName } from "react-device-detect";
import { RotatingLines } from 'react-loader-spinner'
import Alert from '@mui/material/Alert';


export default class HapyningFeed extends React.Component {
  state = _.pick(this.props, "posts", "currentUser", "hapyning", "poll_post", "loading", "reported_post_alert");
  channel = null;
  start_time = null;
  end_time = null;

  handleScroll = (e) => {
    if(e?.target) {
      const bottom = e.target.scrollHeight - e.target.scrollTop <= (e.target.clientHeight + 10);
      if (bottom) {
        this.fetchPosts();
      }
    }
  }
  fetchPosts = () => {
    this.setState({loading: true})
    const { posts, hapyning } = this.state;
    api.lazyLoadPosts(posts.slice(-1)[0].created_at, hapyning.id).then((data) =>{
      this.setState({ posts: [...posts, ...data] });
    })
    this.setState({loading: false})
  }
  handleScrollMobile = () => {
    const e = document.getElementById("mobile-feed");
    const bottom = e.scrollHeight - e.scrollTop <= (e.clientHeight + 10);
    if (bottom)
    {
      this.fetchPosts();
    }
  }
  addOlderPost= (post) =>{
    const { posts } = this.state;
    this.setState({ posts: [...posts, post] });
  }
  addNewPost = (post) => {
    const { posts } = this.state;
    this.setState({ posts: [post, ...posts] });
  };

  removePost = (removedPostId) => {
    const filteredPosts = _.reject(
      this.state.posts,
      (post) => post.id === removedPostId
    );

    this.setState({ posts: filteredPosts });
  };

  createSubscription = () => {
    const { hapyning } = this.state;
    if (hapyning.id) {
      this.channel = consumer.subscriptions.create(
        { channel: "NewPostsChannel", room: hapyning.id },
        { received: (message) => this.handleReceivedMessage(message) }
      );
    }
  };

  handleReceivedMessage = (postIds) => {
    if (this.state.poll_post === false) {
      const { posts } = this.state;
      postIds.forEach((id) => {
        if (!posts.find((post) => post.id == id)) {
          if (this.state.poll_post != true) {
            this.start_time = this.start_time || Date.now();
          }
          this.setState({ poll_post: true });
          return true;
        }
      });
    }
  };
  onBlur = () => {
    this.handleVisibilityChange();
  };

  handleVisibilityChange = () => {
    if (document.visibilityState == "hidden") {
      this.start_time = this.start_time || Date.now();
    } else {
      const { posts, hapyning, currentUser } = this.state;
      this.start_time = this.start_time || Date.now();
      this.end_time = Date.now() - this.start_time;
      const diff_minutes =
        Math.round(((this.end_time % 86400000) % 3600000) / 60000) + 5;
      api.getNewPosts(hapyning.id, diff_minutes, true).then((data) => {
        const { check, post_ids } = data;
        if (check == true) {
          this.handleReceivedMessage(post_ids);
        } else {
          this.start_time = null;
        }
      });
    }
  };

  onFocus = () => {
    this.handleVisibilityChange();
  };
  componentDidMount = () => {
    this.createSubscription();
    if (browserName == "Safari") {
      document.addEventListener(
        "visibilitychange",
        this.handleVisibilityChange
      );
    }
    document.getElementById("mobile-feed").addEventListener("scroll", this.handleScrollMobile)
  };

  componentWillUnmount = () => {
    this.channel.unsubscribe();
    if (browserName == "Safari") {
      document.removeEventListener(
        "visibilitychange",
        this.handleVisibilityChange
      );
    }
    document.getElementById("mobile-feed").removeEventListener("scroll", this.handleScrollMobile)
  };

  showPosts = () => {
    const { posts, hapyning, currentUser } = this.state;
    this.end_time = Date.now() - this.start_time;
    const diff_minutes =
      Math.round(((this.end_time % 86400000) % 3600000) / 60000) + 5;
    this.start_time = null;
    api.getNewPosts(hapyning.id, diff_minutes).then((new_posts) => {
      new_posts.forEach((new_post) => {
        if (!posts.find((post) => post.id == new_post.id)) {
          this.addNewPost(new_post);
        }
      });
    });
    document.getElementById("feed").scroll(0,0)
    document.getElementById("mobile-feed").scroll(0,0)
    this.setState({poll_post: false})
  }

  setAlert = () => {
    this.setState({reported_post_alert: true});
    setTimeout(() => {
      this.setState({reported_post_alert: false});
         }, 5000);
  }

  render() {
    const { showNewPost, is_member, avatar, link_preview,reported_post  } = this.props;
    const { loading, posts, hapyning, currentUser, poll_post, reported_post_alert} = this.state;
    return (
        <div class="uk-position-relative" data-preserve-scroll="true" id="feed" onScroll={this.handleScroll}>
          {reported_post_alert && ( <Alert className="fixedAlert" onClose={() => {this.setState({reported_post_alert: false});}}>This post has been reported.</Alert> )}
          {poll_post && (
            <div className="new-post-button uk-button uk-button-primary uk-button-small">
              <span className="material-icons">arrow_upward</span>
              <button
                className="uk-button uk-button-primary uk-button-small"
                type="button"
                onClick={this.showPosts}
              >
                New Posts
              </button>
            </div>
          )}
          {showNewPost && (
            <NewPost
              avatar={avatar}
              link_preview={link_preview}
              hapyning_id={hapyning.id}
              addNewPost={this.addNewPost}
            />
          )}
          {_.isEmpty(posts) && (
            <div className="no-content-landing">
              <EmptyPosts showNewPost={showNewPost} />
            </div>
          )}
          {_.map(posts, (post) => (
            <PostCard
              key={post.id}
              post={post}
              comments={post.comments}
              current_user={currentUser}
              can_comment={post.can_comment}
              can_delete={post.can_delete}
              hapyning_id={hapyning.id}
              is_member={is_member}
              avatar={avatar}
              likes={post.likes}
              liked_by={post.liked_by}
              link_preview={link_preview}
              full_page_post_url={post.full_page_post_url}
              removePost={this.removePost}
              reported_post={reported_post}
              setAlert={this.setAlert}
            />
          ))}
          {loading && (
            <div className="center-align-loader">
              <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="36"
              visible={true}
              />
            </div>
          )}
        </div>
    );
  }
}
