import Autolinker from 'autolinker';

const VIDEO_SOURCES = [
  "www.youtube.com",
  "vimeo.com",
  "youtu.be"
];

const autolinker = new Autolinker({
  sanitizeHtml: true,
  newWindow: true,
  stripPrefix: false,
  email: false,
  phone: false,
  mention: false,
  hashtag: false,
  replaceFn(match) {
    if (match.getUrl && new URL(match.getUrl()).hostname === window.location.hostname) {
      return match.buildTag().setAttr('target', '_self');
    }
  }
});

export function createLinksForUrls (content) {
  return {__html: autolinker.link(content)}
};

export const hostnameFromUrl = (url) => {
  try {
    const link = new URL(url);
    return link.hostname;
  } catch(e) {
    return null;
  }
};

export const linksInContent = (content = "") => Autolinker.parse(content, {
  phone: false,
  email: false,
  urls: true
});

export const linksContainsVideo = (links) => {
  const videoObject = _.find(links, (link) => {
    const host = hostnameFromUrl(link.getAnchorHref());
    return _.includes(VIDEO_SOURCES, host);
  });
  return videoObject;
};
