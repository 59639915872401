import React, { Component } from 'react';
import UIkit from "uikit";
import PropTypes from 'prop-types';

class ImageGallery extends Component {
  static defaultProps = {
    images: []
  }

  render() {
    const {images} = this.props;

    const openLightBox = (index) => {
      const lightboxImages = _.map(images, ({url}) => ({source: url}));
      UIkit.lightboxPanel({items: lightboxImages}).show(index);
    }

    return (
      <div className="resource-gallery-grid">
        {_.map(images, (image, index) => {
          return (
            <div className="image" key={`image-${index}`}
            style={{backgroundImage: `url(${image.url})`}}
              onClick={openLightBox.bind(this, index)}
            ></div>
          );
        })}
      </div>
    );
  }
}

ImageGallery.propTypes = {
  images: PropTypes.array.isRequired
};

export default ImageGallery;
