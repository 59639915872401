import React from 'react';
import { stopEverything } from './helpers/events';
import InteractionBar from "./posts/InteractionBar";
import Comment from './posts/Comment';
import CommentForm from "./posts/CommentForm";

const VISIBLE_COMMENTS = 3;
const ALL_COMMENTS = 1000;

class Comments extends React.Component {
  state = {
    show: false,
    visible: VISIBLE_COMMENTS,
    comments: _.get(this.props, 'comments', [])
  }

  newContentField = React.createRef()

  toggleCommentsVisibility = (focusInput) => () => {
    this.setState(({show}) => ({show: !show}), () => {
      if (focusInput && this.state.show) this.newContentField.current.focus();
    });
  }

  addComment = (comment) => {
    this.setState(({comments, visible}) => ({
      comments: [...comments, comment],
      visible: visible + 1
    }));
    this.newContentField.current.blur();
  }

  updateComment = (comment) => {
    this.setState(({comments}) => {
      const otherComments = _.remove(comments, (c) => c.id !== comment.id);
      return {comments: [...otherComments, comment]};
    });
  }

  removeComment = (id) => {
    this.setState(({comments}) => {
      const otherComments = _.remove(comments, (c) => c.id !== id);
      return {comments: otherComments};
    });
  }

  markCommentEditable = (id, editable) => {
    return () => {
      this.setState(({comments}) => ({
        comments: _.map(comments, (comment) => ({
          ...comment,
          editable: comment.id === id ? editable : false
        }))
      }));
    };
  }

  viewAll = () => this.setState({visible: ALL_COMMENTS})

  render() {
    const {
      liked_by, likes, post, full_page_post_url, avatar, can_comment, current_user, is_member
    } = this.props;
    const {show, visible, comments} = this.state;
    const sortedComments = _.sortBy(comments, 'created_at');

    return (
      <div className="post-comments-section">
        {is_member && (
          <InteractionBar
            liked_by={liked_by}
            likes={likes}
            post={post}
            full_page_post_url={full_page_post_url}
            countComments={comments.length}
            toggleComments={this.toggleCommentsVisibility}
            showComments={show}
            canComment={can_comment}/>
        )}
        {(show || !is_member) && (
          <div className="post-comments">
            {comments.length > visible && (
              <button className="uk-center show-previous-comments" onClick={stopEverything(this.viewAll)}>
                See Previous Comments
              </button>
            )}
            {_.map(_.takeRight(sortedComments, visible), (comment) => {
              if (comment.editable && can_comment) {
                return <CommentForm key="edit-comment-form"
                  postId={post.id}
                  avatar={avatar}
                  save={this.updateComment}
                  initialState={comment}
                  placeholder="Edit Comment..."
                  className="post-comment-edit"
                  markCommentEditable={(e) => stopEverything(this.markCommentEditable(comment.id, false))}/>
              } else {
               return <Comment key={comment.id}
                  comment={comment}
                  currentUser={current_user}
                  canComment={can_comment}
                  removeComment={this.removeComment}
                  markCommentEditable={(e) => stopEverything(this.markCommentEditable(comment.id, true))}/>
              }
            })}
            {can_comment && (
              <CommentForm key="new-comment-form"
                postId={post.id}
                avatar={avatar}
                save={this.addComment}
                contentRef={this.newContentField}
                placeholder="Add Comment..."/>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Comments;
