import React, { Component } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-custom-build";
import CustomCKEditorConfig from "./CustomCKEditorConfig";
import { watchForChanges, setSaveButtonDisabledState } from "../packs/form_helpers";
const watchFileUpload = (setUploadState = setSaveButtonDisabledState) => (editor) => {
  const fileRepositoryPlugin = editor.plugins.get('FileRepository');
  fileRepositoryPlugin.loaders.on('add', () => setUploadState(1));
  fileRepositoryPlugin.loaders.on('remove', () => setUploadState(-1));
};
class RichTextEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textAreaContent: props.content || "",
      matchedNumbers: []
    }
  }
  render() {
    const { className, field_name, content, onUpdate, setUploadState } = this.props;
    const { textAreaContent, matchedNumbers } = this.state;
    const watchPhoneFormator = (editor) => {
      // phone number regex
      const pattern1 = /^[(\[][0-9]{3}[)\]]$/;
      const pattern2 = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
      // get ck editor data
      let originalCKData = editor.getData();
      let ckData = originalCKData;
      // replace html tags from ckData
      ckData = ckData.replace(/(<([^>]+)>)/g, ' ');
      // replace alphabets tags from ckData
      ckData = ckData.replace(/[A-Za-z]/g, ' ')
      // convert ckData string words into array
      ckData = ckData.split(' ');
      ckData = ckData.filter(Boolean);
      ckData?.map((data, index) => {

        data = data.replace('&nbsp;', ' ');
        data = data.replace('&', ' ');
        data = data.replace(';', ' ');
        data = data.trim();
        if (data.length > 0) {
          const currentIndexResult = pattern1.test(ckData[index]);
          if (currentIndexResult) {
            ckData[index + 1] = ckData[index].concat(' ' + ckData[index + 1]);
            ckData[index] = '';
            if (index > 0 && ckData[index - 1] === "+1") {
              ckData[index + 1] = ckData[index - 1].concat(' ' + ckData[index + 1]);
              ckData[index - 1] = '';
            }
          }
          const nextIndexResult = pattern2.test(data);

          if (nextIndexResult) {
            this.setState(prevState => ({
              matchedNumbers: [...prevState.matchedNumbers, data]
            }))
            originalCKData = originalCKData.replace(`<p>${data}</p>`, `<a href='tel:${data}'>${data}</a>`)
            originalCKData = originalCKData.replace(`<h1>${data}</h1>`, `<h1><a href='tel:${data}'>${data}</a></h1>`)
            originalCKData = originalCKData.replace(`<h2>${data}</h2>`, `<h2><a href='tel:${data}'>${data}</a><h2>`)
            originalCKData = originalCKData.replace(`<h3>${data}</h3>`, `<h3><a href='tel:${data}'>${data}</a><h3>`)
            originalCKData = originalCKData.replace(`<p>${data}`, `<a href='tel:${data}'>${data}</a>`)
            originalCKData = originalCKData.replace(`${data}</p>`, `<a href='tel:${data}'>${data}</a>`)
          }
          else {
            const newNumber = ckData[index].concat(ckData[index + 1]);
            matchedNumbers.map((number) => {
              if (ckData.length > 1 && ckData[index + 1] !== undefined && pattern2.test(newNumber)) {
                originalCKData = originalCKData.replace(`<a href="tel:${number}">${data}</a>${ckData[index + 1]}`, `<a href="tel:${newNumber}">${newNumber}</a>`)
              }
            })
          }
        }
      })
      return originalCKData;
    }
    return (
      <div className={`RichTextEditor ${className}`}>
        <textarea name={field_name} value={textAreaContent} readOnly autoFocus></textarea>
        <CKEditor
          onInit={watchFileUpload(setUploadState)}
          editor={ClassicEditor}
          config={CustomCKEditorConfig}
          data={content}
          onChange={(event, editor) => {
            if (!setUploadState) watchForChanges();
            const textAreaContent = watchPhoneFormator(editor)
            this.setState({ textAreaContent });
            if (_.isFunction(onUpdate)) onUpdate(textAreaContent);
          }}
        />
      </div>
    );
  }
}
export default RichTextEditor;
