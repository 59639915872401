import React, { useEffect, useState } from "react";
import { v1 as uuid } from "uuid";
import { getPostPerPage } from "../api";
import FeedPost from "./FeedPost";

function MemoizedFeedPost({ post, comments, can_comment, can_delete, hapyning, is_member, current_user, avatar, likes, liked_by, full_page_post_url, full_page_view, link_preview, hapyning_icon, hapyning_parent }) {
  return (
    <FeedPost
      key={uuid()}
      post={post}
      comments={comments}
      can_comment={can_comment}
      can_delete={can_delete}
      hapyning={hapyning}
      is_member={is_member}
      current_user={current_user}
      avatar={avatar}
      likes={likes}
      liked_by={liked_by}
      full_page_post_url={full_page_post_url}
      full_page_view={full_page_view}
      link_preview={link_preview}
      hapyning_icon={hapyning_icon}
      hapyning_parent={hapyning_parent}
    />
  );
}

const MemoizedFeedPostComponent = React.memo(MemoizedFeedPost);

export default function HomeFeed({ current_user, avatar }) {
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [available, setAvailable] = useState(true);
  const [loading, setLoader] = useState(false);

  useEffect(() => {
    loadMorePosts();
  }, []);

  const loadMorePosts = () => {
    setLoader(true);
    getPostPerPage(page).then((res) => {
      setPosts((prevState) => [...prevState, ...res.posts]);
      setPage((prevState) => prevState + 1);
      console.log(res.posts.length)
      if( res.posts.length !== 5)
      setAvailable(false);
      setLoader(false);
    });
  };

  return (
    <>
      <div
        className="hapyning feed-height uk-overflow-auto uk-position-relative uk-flex uk-flex-center uk-width-1-1"
        id="postsFeed"
        onScroll={(event) => {
          const element = event.target;
          if (
            Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight ) < 12 && available
          )
            loadMorePosts();
        }}
      >
        <div
          className="uk-width-1-2@s uk-width-1-1 "
        >
          <h3>Feed</h3>
          {posts.map((element) => {
            return (
              <MemoizedFeedPostComponent
                post={element.post}
                comments={element.comments}
                can_comment={element.can_comment}
                can_delete={element.can_delete}
                hapyning={element.hapyning}
                is_member={true}
                current_user={current_user}
                avatar={avatar}
                likes={element.likes}
                liked_by={element.liked_by}
                full_page_post_url={element.full_page_post_url}
                full_page_view={true}
                link_preview={element.link_preview}
                hapyning_icon={element.hapyning_icon}
                hapyning_parent={element.hapyning_parent}
              />
            );
          })}
          {loading && (
            <div className="uk-text-primary" uk-spinner="ratio: 3"></div>
          )}
        </div>
      </div>
    </>
  );
}
