import React from 'react';
import classnames from 'classnames';
import RichTextForm from '../attachables/RichTextForm';
import GalleryForm from '../attachables/GalleryForm';
import VideoForm from '../attachables/VideoForm';
import LinkCollectionField from '../fields/LinkCollectionField';
import FileForm from '../attachables/FileForm';
import { validAttachable } from '../helpers/attachable';

const TEXT_LIMIT = 400;
const DEFAULT_ERROR_MESSAGE = 'An error occurred. Resource was not saved.';

const ATTACHABLE_TYPES = [
  { type: "RichText", label: "Rich Text", form: RichTextForm, icon: "rich-text", defaults: { content: "" } },
  { type: "Gallery", label: "Photo", form: GalleryForm, icon: "photo", defaults: { images: [] } },
  { type: "VideoLibrary", label: "Video", form: VideoForm, icon: "video", defaults: {} },
  { type: "FileLibrary", label: "File", form: FileForm, icon: "file", defaults: { files: [] } },
  { type: "LinkCollection", label: "Link", form: LinkCollectionField, icon: "link", defaults: { links: [] } },
];

export default class Form extends React.Component {
  state = {
    ...this.props.resource,
    previous_attachable: this.props.resource.attachable,
    uploadsInProgress: 0,
    errorMessage: null,
    saving: false,
    edit: this.props.edit
  }

  set = (key) => ({ target }) => this.setState({ [key]: target.value })

  setAttachable = (values) => {
    this.setState(({ attachable }) => ({
      attachable: {
        ...attachable,
        ...(_.isFunction(values) ? values(attachable) : values)
      }
    }))
  }

  selectAttachable = (type) => {
    if (!validAttachable(this.state.attachable) || window.confirm('Do you want to discard changes?')) {
      const { defaults = {} } = _.find(ATTACHABLE_TYPES, { type });
      this.setState({ attachable: { type, ...defaults } });
    }
  }

  changeUploadCount = (uploadChange) => this.setState(
    ({ uploadsInProgress }) => ({ uploadsInProgress: uploadsInProgress + uploadChange })
  )

  save = () => {
    if (!this.valid()) return;

    this.setState({ saving: true });
    this.props.save(this.state)
      .catch((response) => {
        const errorMessage = _.get(response, 'responseJSON.error.message', DEFAULT_ERROR_MESSAGE);
        this.setState({ saving: false, errorMessage });
      })
  };

  cancel = () => window.history.back();

  valid = () => {
    const { title, attachable, preview_content } = this.state;
    return title && validAttachable(attachable) && (preview_content.length <= TEXT_LIMIT);
  }

  render() {
    const {
      attachable,
      previous_attachable,
      errorMessage,
      preview_content,
      title,
      uploadsInProgress,
      saving
    } = this.state;
    const selectedAttachable = _.find(ATTACHABLE_TYPES, { type: attachable?.type });
    const AttachableForm = selectedAttachable?.form;
    const invalidPreviewLength = preview_content.length > TEXT_LIMIT;
    return (
      <div className="hapyning-content layout-panels new-resource-container">
        <div className="hapyning panel-content uk-flex uk-flex-column">
          <div className="uk-padding">
            <h4 className="uk-margin-remove uk-display-inline-block">{this.props.title}</h4>
            <button className="uk-button uk-button-primary uk-align-right uk-visible@m" onClick={this.save} disabled={!this.valid() || uploadsInProgress || saving}>{saving ? "Saving" : "Save"}</button>
            <button className="uk-button uk-button-default uk-align-right uk-visible@m" onClick={this.cancel}>Cancel</button>
          </div>
          <div className="uk-padding">
            <div>
              {errorMessage && <div className="uk-alert uk-alert-danger">{errorMessage}</div>}
              <div className="field">
                <label htmlFor="resource[title]">Title</label>
                <input type="text"
                  value={title}
                  onChange={this.set('title')}
                  required
                  autoFocus
                  id="resource[title]"
                  className="uk-input"
                  placeholder="Enter Text"
                />
              </div>
              <div className="field">
                <div className="uk-form-controls">
                  <label htmlFor="resource[preview_content]">Description</label>
                  <div className="field-info">Optional</div>
                  <textarea
                    rows="6"
                    id="resource[preview_content]"
                    className="preview-description uk-textarea"
                    onChange={this.set('preview_content')}
                    value={preview_content}
                    placeholder="The text and photos here will show in the Resource Preview"
                  />
                  <p className={classnames("field-info uk-float-right uk-margin-remove-top", { "uk-text-danger": invalidPreviewLength })}>
                    {invalidPreviewLength && <>
                      Description is too long (maximum is {TEXT_LIMIT} characters)
                    </>}
                    {' '}
                    {preview_content.length}/{TEXT_LIMIT}
                  </p>
                </div>
              </div>
              <div className="attachable-options uk-margin-xlarge-bottom">
                {_.map(ATTACHABLE_TYPES, ({ label, type, icon }) => (
                  <button key={type}
                    type="button"
                    className={classnames("uk-button uk-button-default", { active: label === selectedAttachable?.label })}
                    onClick={() => this.selectAttachable(type)}
                  >
                    <i className={icon} aria-hidden="true"></i>
                    {label}
                  </button>
                ))}
              </div>
              {attachable && <>
                <h4>{selectedAttachable.label}</h4>
                <AttachableForm attachable={attachable}
                  setAttachable={this.setAttachable}
                  uploadsInProgress={uploadsInProgress}
                  changeUploadCount={this.changeUploadCount}
                  isResource
                />
              </>}
            </div>
          </div>
          <div className="resource-form-action-container-mobile uk-hidden@m uk-margin-auto-top">
            <button className="uk-button uk-button-default uk-width-1-2 uk-margin-small-left uk-margin-small-right" onClick={this.cancel}>Cancel</button>
            <button className="uk-button uk-button-primary uk-width-1-2 uk-margin-small-left uk-margin-small-right" onClick={this.save} disabled={!this.valid() || uploadsInProgress || saving}>{saving ? "Saving" : "Save"}</button>
          </div>
        </div>
      </div>
    )
  }
}
