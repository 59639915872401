$(document).on("turbolinks:load", function () {
  var parentDiv = document.querySelector("#hapyning-section-1");
  var childDiv1 = document.getElementById("child-div1");
  var childDiv2 = document.getElementById("child-div2");

  var lastScrollPosition = parentDiv.scrollTop;

  childDiv2.style.top = "0";
  childDiv1.style.top = "0";
  parentDiv.addEventListener("scroll", function () {
    var currentScrollPosition = parentDiv.scrollTop;
    var div1Height = childDiv1.scrollHeight;
    var div2Height = childDiv2.scrollHeight;
    var parentDivHeight = parentDiv.clientHeight;
    if (div1Height >= div2Height) {
      childDiv1.style.position = "relative";

      childDiv2.style.position = "sticky";
      if (currentScrollPosition < lastScrollPosition) {
        if (parseInt(childDiv2.style.top) < 0) {
          childDiv2.style.top = parseInt(childDiv2.style.top) + 10 + "px";
        }
      } else {
        if (parseInt(childDiv2.style.top) > (-1 *(div2Height - parentDivHeight))) {
          childDiv2.style.top = parseInt(childDiv2.style.top) - 10 + "px";
        }
      }
    } else {
      childDiv1.style.position = "sticky";
      childDiv2.style.position = "relative";
      if(div1Height > parentDivHeight){
        if (currentScrollPosition < lastScrollPosition) {
          if (parseInt(childDiv1.style.top) < 0) {
            childDiv1.style.top = parseInt(childDiv1.style.top) + 10 + "px";
          }
        } else {
          if (parseInt(childDiv1.style.top) > (-1 *(div1Height - parentDivHeight))) {
            childDiv1.style.top = parseInt(childDiv1.style.top) - 10 + "px";
          }
        }
      }

      childDiv2.style.top = "0";
    }
    lastScrollPosition = currentScrollPosition;
  });
});
