jQuery(function($) {
  $.initialize("[data-uploadcare-for]", function() {
    const $uploadcareLink = $(this);
    const $thumbnail = $(this).find('.thumbnail').first();
    const $input = $("#" + $(this).data('uploadcare-for'));
    const autoSubmit = $(this).data('auto-submit');
    const widget = uploadcare.SingleWidget($input, $uploadcareLink);
    const thumbnailSizeUri = thumbnailUriFor($thumbnail);

    $uploadcareLink.on('click', () => widget.openDialog($input.val(), $input.data()));

    skipChangeEventOnInitialize(() => {
      widget.onChange(function(file) {
        $thumbnail.attr('src', '');
        if (file) {
          file.done(function(fileInfo) {
            if (autoSubmit) $input.closest('form').submit();
            $thumbnail.attr('src', fileInfo.cdnUrl + thumbnailSizeUri);
          });
        }
      });
    });
  });

  function thumbnailUriFor($thumbnail) {
    if ($thumbnail.width() && $thumbnail.height()) {
      const size = `${Math.round($thumbnail.width() * 2)}x${Math.round($thumbnail.height() * 2)}`;
      return `-/scale_crop/${size}/center/`;
    }
    return '';
  }

  function skipChangeEventOnInitialize(func) {
    setTimeout(func, 1);
  }
});
