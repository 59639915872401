import React from 'react';
import classnames from 'classnames';

const IMAGE_ATTRIBUTES = ['alt', 'width', 'height'];

function sizeOperations({width, height}) {
  if (width && height) {
    return `${width*3}x${height*3}`;
  }
  return "";
}

export default function UploadcareImage(props) {
  const {image, defaultImage, circle, className, ...attributes} = props;
  const src = image ? image + sizeOperations(attributes) : defaultImage;

  return <img src={src}
    className={classnames(className, {"uk-border-circle": circle})}
    {..._.pick(attributes, IMAGE_ATTRIBUTES)} />;
}
