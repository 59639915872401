import { v4 as uuid } from 'uuid';
import UIKit from 'uikit';
import React from 'react';
import qs from 'querystring';

import { isMobile } from 'react-device-detect';
import { stopEverything } from '../helpers/events';
import { linksInContent, linksContainsVideo } from '../helpers/links';
import { addImageToGallery, prepareMedia, noAttachablePresent, validAttachable, addFileToFileLibrary } from '../helpers/attachable';
import { createFileAsset, fetchLinkPreview, getFileAsset, updateLinkCollection, deleteGallery } from '../../api';
import AlertModal from '../AlertModal';
import { WebLinkPreview } from '../Extras';

import ImageUploader from './ImageUploader';
import FileUploader from './FileUploader';
import VideoUploader from './VideoUploader';
import PostContentField from './PostContentField';
import AttachmentField from './AttachmentField';
import NEWSPAPER_ICON from "newspaper-primary.svg";
import copyLink from "copy-link.svg";
import VideoUploadPlaceholder from '../shared/VideoUploadPlaceholder';
import DragAndDrop from '../shared/DragAndDrop';
import ClipLoader from "react-spinners/ClipLoader";
import VideoForm from '../attachables/VideoForm';
import AddImageField from "../fields/AddImageField";

import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';


const toggleUkModal = (id) => UIKit.toggle(document.getElementById(id)).toggle();
const LINK_PREVIEW_DEBOUNCE = window.TEST_MODE ? 0 : 1500;

class Form extends React.Component {
  state = {
    ..._.get(this.props, 'post', {}),
    content: this.props.initialContent || "",
    interacting: this.props.initialContent || this.props.editing || false,
    modified: false,
    preview: this.props.initialLinkPreview || {},
    saving: false,
    uploading: false,
    uploadsInProgress: 0,
    uploadZone: false,
    linkUpload: false,
    previousAttachable: undefined,
    showDragDrop: false,
    isImage: true
  }

  postForm = React.createRef()
  abandonChangesModalId = uuid();

  componentDidMount() {
    document.addEventListener('mousedown', this.clickOutsideForm);
    this.fetchPreview();
    const { attachable } = this.state
    this.setState({
      previousAttachable: attachable
    })
    if (attachable?.type === 'VideoLibrary') {
      this.setState({ uploadZone: true })
    }

  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.clickOutsideForm);
  }

  toggleShowDragDrop = (isImage = true) => {
    this.setState({ showDragDrop: !this.state.showDragDrop, uploadZone: false, linkUpload: false, isImage: isImage });
  }

  clickOutsideForm = (event) => {
    const clickIsOutsideForm = !this.postForm.current.contains(event.target);
    if (clickIsOutsideForm && this.state.interacting && !this.valid() && !this.state.uploading) {
      this.cancel(false);
    }
  }

  setFocus = () => {
    this.setState({ interacting: true })
  }

  valid = () => {
    const { content, attachable, uploading } = this.state;
    const hasContent = !_.isEmpty(_.trim(content));
    return !uploading && (hasContent || validAttachable(attachable));
  }

  save = () => {
    const { editing, setFlag, flag } = this.props;
    const { content, attachable, attachable_type, previousAttachable, removedImages } = this.state;

    if (removedImages != undefined) {
      if (content.length >= 1 && removedImages.length >= 1 && previousAttachable.type === "Gallery") {
        deleteGallery(previousAttachable.id);
        setFlag();
      }
    }
    if (this.valid()) {
      if (attachable_type === "LinkCollection" && this.props.editing) {
        if (this.state.attachable.type !== "VideoLibrary") {
          updateLinkCollection(attachable, this.props.post.id)
        }
      }
      else if (previousAttachable && previousAttachable?.type === "RichText" && attachable.type !== "FileAsset") {
        this.setState((prevState) => ({
          ...prevState,
          attachable: {
            ...attachable,
            id: undefined
          }
        }))
      }
      else if (attachable && attachable?.id === undefined && editing && previousAttachable && previousAttachable.id !== undefined && previousAttachable?.type !== "LinkCollection" && attachable.type !== "Gallery") {
        this.setState((prevState) => ({
          ...prevState,
          attachable: {
            ...attachable,
            id: previousAttachable.id
          }
        }))
      }
      this.setState({ saving: true, uploading: true });
      setTimeout(() => {
        this.props.onSave(this.state).catch(() => {
          this.setState({ saving: false, uploading: false, interacting: false });
        });
      }, 500)
    }
  }

  submitOnEnter = (e) => {
    const onlyEnterPressed = e.key === 'Enter' && !e.shiftKey;
    if (onlyEnterPressed && !isMobile) {
      e.preventDefault();
      this.save();
    }
  }

  updateInput = ({ target: { value } }) => {
    this.setState({ content: value, modified: true }, this.fetchPreview);
  }

  setAttachmentPreview = async (file) => {
    try {
      const image = await prepareMedia(file);
      this.setState((state) => addImageToGallery(state, image));
    } catch (e) {
      this.setState({ uploading: false })
    }
  }

  fetchPreview = _.debounce(() => {
    if (!_.isEmpty(this.state.attachable)) return;
    const links = linksInContent(this.state.content);
    const videoLink = linksContainsVideo(links);

    if (videoLink) {
      this.setState({ preview: { video: videoLink.getAnchorHref() } });
    } else if (!_.isEmpty(links) && links[0].getAnchorHref() !== _.get(this.state, 'preview.link')) {
      this.setState({ preview: { loading: true } });
      fetchLinkPreview(links[0].getAnchorHref())
        .then((data) => this.setState({ preview: { ...data, link: links[0].getAnchorHref() } }));
    } else if (_.isEmpty(links)) {
      this.setState({ preview: {} });
    }
  }, LINK_PREVIEW_DEBOUNCE)

  removePreview = () => {
    this.setState({ preview: { disable: true, link: _.get(this.state, 'preview.link') }, modified: true });
  }

  saveFile = (attachable_type) => (result) => {
    this.setState(({ uploading }) => {
      if (uploading) {
        return {
          uploading: result.file_processing,
          modified: true,
          attachable: result,
          attachable_id: result.id,
          attachable_type,
          file_processing: result.file_processing
        };
      }
    }, () => {
      this.poll(this.state.attachable.id);
    });
  }

  poll = (id) => {
    if (!this.polling) {
      this.polling = true;
      const poller = () => {
        getFileAsset(id).then((fileAsset) => {
          if (fileAsset.file_processing) {
            if (this.polling) {
              this.setState({ uploading: false, file_processing: false });
              this.polling = false;
            }
          }
          if (this.polling) {
            setTimeout(poller, 2000);
          }
        });
      }
      if (this.state.file_processing) {
        poller();
      }
    }
  }

  removeFile = () => {
    this.setState(({ attachable, removedFiles }) => ({
      previousAttachable: attachable.type === "RichText" ? attachable : null,
      attachable: null,
      attachable_type: null,
      modified: true,
      removedFiles: [
        ...(removedFiles || []),
        attachable
      ]
    }));
  }

  removeImageFromGallery = (image, index) => {
    this.setState((state) => {
      const { attachable, removedImages, attachable_type } = state;
      const removedImage = _.find(attachable.images, (img, i) => {
        return img === image && index === i;
      });
      const images = _.without(attachable.images, removedImage);

      return {
        attachable_type: images.length === 0 ? undefined : attachable_type,
        attachable: images.length === 0 ? undefined : { ...attachable, images },
        modified: true,
        removedImages: [
          ...(removedImages || []),
          removedImage
        ]
      }
    });
  }

  removeFileFromLibrary = (file, index) => {
    this.setState(({ attachable, removedLibraryFiles, previousAttachable }) => {
      const removedFile = _.find(attachable.files, (f, i) => {
        return file === f && index === i;
      });
      const files = _.without(attachable.files, removedFile);

      return {
        attachable: { ...attachable, files },
        previousAttachable: { ...attachable, files },
        modified: true,
        removedLibraryFiles: [
          ...(removedLibraryFiles || []),
          removedFile
        ]
      };
    });
  }

  initiateUpload = async ({ target }) => {
    const files = target.files;
    const { attachable, previousAttachable } = this.state;
    const isFileLibraryType = (attachable) => !_.isNil(attachable) && attachable.type === "FileLibrary" && attachable.files.length > 0;
    this.setState({ uploading: true }, () => {
      if (!isFileLibraryType(attachable) && _.every(files, (f) => f.type.startsWith("image/"))) {
        _.each(files, async (file) => {
          if (file.type.startsWith('image/')) {
            this.setAttachmentPreview(file);
          }
          if (previousAttachable?.links?.length === 0 || previousAttachable?.files?.length === 0) {
            this.setState((prevState) => ({
              ...prevState,
              attachable: {
                ...attachable,
                id: undefined
              }
            }))
          }
        });
      } else if (!isFileLibraryType(attachable) && files.length == 1 && _.last(files).type.startsWith("video/")) {
        createFileAsset(_.last(files)).done(this.saveFile("FileAsset")).fail((e) => {
          this.setState({ uploading: false, modified: true });
        });
      } else {
        _.each(files, (file) => {
          this.setState((state) => addFileToFileLibrary(state, {
            content_type: file.type,
            file,
            identifier: file.name
          }));
        });
      }
      this.setState({ uploadZone: false });
    });
  }

  cancel = (event) => {
    if (event && (this.valid() || this.state.uploading)) {
      toggleUkModal(this.abandonChangesModalId);
    } else {
      this.setState({ interacting: false, modified: false });
      this.props.onCancel();
    }
  }

  createRichText = () => {
    const postContent = this.state.content;
    const { hapyningId, post } = this.props;
    const params = qs.stringify({
      postContent,
      hapyningId,
      postId: _.get(post, 'id')
    });
    window.location = `/rich_texts/new?${params}`;
  }

  updateLinkCollection = (linkCollection = { type: "LinkCollection", links: [], newLink: true }) => {
    if (linkCollection.links.length === 0) {
      this.setState({
        attachable: undefined,
        previousAttachable: undefined
      })
    }
    else {
      linkCollection["newLink"] = false;
    }
    this.setState({ attachable_type: "LinkCollection", attachable: linkCollection, modified: true, previousAttachable: linkCollection });
  }

  changeUploadCount = (uploadChange) => {
    this.setState(
      ({ uploadsInProgress }) => ({ uploadsInProgress: uploadsInProgress + uploadChange }))
    this.setState({ modified: true });
  }

  setAttachable = (values) => {
    this.setState(({ attachable }) => ({
      attachable: {
        ...attachable,
        ...(_.isFunction(values) ? values(attachable) : values)
      }
    }))
    this.setState(({ previousAttachable }) => ({
      previousAttachable: {
        ...previousAttachable,
        ...(_.isFunction(values) ? values(previousAttachable) : values)
      }
    }))
  }

  showUploadZone = () => {
    this.setState({ showDragDrop: false, uploadZone: !this.state.uploadZone, linkUpload: false });
  }

  linkUploadZone = () => {
    this.setState({ showDragDrop: false, linkUpload: !this.state.linkUpload, uploadZone: false });
  }

  render() {
    const { avatar, editing } = this.props;
    const {
      content, attachable_type, attachable,
      interacting, saving, uploading, preview, modified, uploadsInProgress, uploadZone,
      linkUpload, previousAttachable, showDragDrop, isImage
    } = this.state;

    return (
      <>
        {attachable_type !== "FileAsset" && uploading ? <div className="loader-styling"><ClipLoader color='#19b2a7' size='50' /></div> :
          <DragAndDrop initiateUpload={this.initiateUpload}
            post={true}
            attachable={attachable}>
            <div ref={this.postForm}>
              <PostContentField
                avatar={avatar}
                content={content}
                updateInput={this.updateInput}
                submitOnEnter={this.submitOnEnter}
                setFocus={this.setFocus} />
              {uploading && attachable_type === "FileAsset" && <VideoUploadPlaceholder cancelUpload={this.cancel} />}
              {interacting && <>
                <div className="post-form-preview uk-flex uk-flex-wrap">
                  {!uploading && !noAttachablePresent(attachable) && (
                    <AttachmentField
                      initiateUpload={this.initiateUpload}
                      attachable_type={attachable_type}
                      attachable={attachable}
                      removeFileFromLibrary={this.removeFileFromLibrary}
                      removeFile={this.removeFile}
                      removeImage={this.removeImageFromGallery}
                      updateLinkCollection={this.updateLinkCollection}
                      linkUpload={linkUpload}
                      editing={this.props.editing}
                      canEdit={this.props.canEdit}
                    />
                  )}
                  {!uploading && noAttachablePresent(attachable) && <>
                    <div className="uk-flex uk-flex-left">
                     <ImageUploader toggleShowDragDrop={this.toggleShowDragDrop} />
                      <VideoUploader showUploadZone={this.showUploadZone} />
                      <FileUploader toggleShowDragDrop={this.toggleShowDragDrop} />
                      <button className="post-link-attachment-button" id="attach_link" onClick={(_) => { this.updateLinkCollection(); this.linkUploadZone() }}>
                        <img src={copyLink} aria-hidden="true"></img>
                      </button>
                    </div>
                    <div className="uk-margin-auto-left">
                      <a onClick={this.createRichText} href="#" className="post-create-article-link">
                        <img src={NEWSPAPER_ICON} alt="" />Create Rich Text
                      </a>
                    </div>
                  </>}
                  {(showDragDrop || (editing && (!linkUpload && !uploadZone) &&
                  (attachable_type == "Gallery" || attachable_type == "FileLibrary" || attachable_type == "FileAsset"))) &&
                  <div className='uk-width-1-1 uk-margin-top'>
                  <div className='drag-drop-container uk-flex-column uk-margin-top'>
                    <span className='uk-margin-medium-top uk-margin-small-bottom'><CloudUploadOutlinedIcon  sx={{ fontSize: "29px" }} /></span>
                    <div className='uk-margin-small-bottom'>Drag and drop files here</div>
                    <div className='uk-margin-small-bottom'>or</div>
                    <label className="uk-button uk-button-secondary add-file" data-upload-type={isImage ? 'image': 'file'}>
                      <input type="file" accept={isImage ? 'image/*' : '*'} onChange={this.initiateUpload} hidden multiple/>
                    BROWSE FILES</label>
                  </div>
                </div>}
                </div>
                {!attachable && <WebLinkPreview preview={preview} removePreview={this.removePreview} />}
                {!uploading && uploadZone && (
                  <div className='video-link-form' >
                    <VideoForm
                      attachable={attachable}
                      setAttachable={this.setAttachable}
                      uploadsInProgress={uploadsInProgress}
                      changeUploadCount={this.changeUploadCount}
                    >
                    </VideoForm>
                  </div>
                )}
                {!uploading && <div className="post-form-actions uk-flex uk-flex-right">
                  <button
                    disabled={attachable?.type === "VideoLibrary" && attachable?.videos === null}
                    className="uk-button uk-button-secondary"
                    hidden={saving}
                    onClick={this.cancel}
                  >
                    Cancel
                  </button>
                  <button type="submit"
                    disabled={!modified || !this.valid() || uploadsInProgress}
                    className="post-tag uk-button uk-button-primary"
                    onClick={stopEverything(saving ? _.noop : this.save)}
                  >
                    {saving ? "Saving" : editing ? "Save" : "Post"}
                  </button>
                </div>}
              </>}

              <AlertModal
                bodyText="Do you want to leave before saving changes?"
                actionText="Abandon Changes"
                action={() => this.cancel(false)}
                elementId={this.abandonChangesModalId} />
            </div>
          </DragAndDrop>
        }
      </>
    );
  }
}

export default Form;
