import React from 'react';
import MORE_DOTS from 'more.svg';

export default function MoreOptions({title="more", dotClasses="", children}) {
  return <>
    <a href="#" className={`more-options ${dotClasses}`} title={title}>
      <img width="30" src={MORE_DOTS}/>
    </a>
    <div className="uk-navbar-dropdown uk-position-fixed uk-dropdown" uk-dropdown="pos: bottom-right; mode: click" uk-toggle="true">
      <ul className="uk-nav uk-navbar-dropdown-nav">
        {children}
      </ul>
    </div>
  </>;
}
