import React from "react";
import greenLink from "mdi-link-variant@1.5x.svg";

export default function LinkCollectionPreview({attachment, classes="post-body"}) {
  if (!attachment) return null;

  return (
    <div className={classes}>
      <ul className="uk-list">
        {_.map(attachment.links, ({id=_.uniqueId('link-'), url, title}) => (
          <li key={id}>
            <img src={greenLink} aria-hidden="true"></img>
            <a href={url} rel="nofollow" target="_blank">{title || url}</a>
          </li>
        ))}
      </ul>
    </div>
  );
}
