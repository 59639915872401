
jQuery(function($) {
  var paused = true;

  $(document).on('click', '.about-hapyn-video', function (e) {
    e.preventDefault();

    if (paused) {
      e.target.play();
      paused = false;
    } else {
      e.target.pause();
      paused = true;
    }
  });
});
