import React from 'react';

const UploaderButton = ({label, screenReader, ...fileProps}) => (
  <label className="uploader">
    <span className="uk-button uk-button-default">{label}</span>
    <span className="screen-reader-text">{screenReader}</span>
    <input type="file" hidden {...fileProps}/>
  </label>
);

export default UploaderButton;
