function tabChange(tabNumber) {
    for (let i = 1; i < 5; i++) {
      var element = document.getElementById(`tab${i}`);
      if (tabNumber === i) {
        element.classList.add("uk-active");
      }
      else {
        element.classList.remove("uk-active");
      }
    }
  }

$(document).on("click", ".about-class", function (e) {
  $('#about').show();
  $('#about-hapyning-modal').show();
  $('.post-form-field').hide();
  $('.mobile-content').hide();
  $('.resources-members-box').hide();
  $('.settings-title').hide();
  $('.uk-button').hide();
  $('.resource-index').hide();
  $('.member-view').hide();
});

window.tabChange = tabChange;
