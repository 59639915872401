import React from 'react';
import { Provider, ErrorBoundary, LEVEL_WARN } from '@rollbar/react';

const rollbarConfig = {
  accessToken: $('meta[name="rollbar-key"]').attr('content'),
  environment: $('meta[name="rollbar-env"]').attr('content'),
};

const ErrorDisplay = ({ error, resetError }) => (
  <div className="error-boundary-container uk-card uk-card-default">
    <p className="error-boundary-message">Uh oh!<br/>Something went wrong.</p>
  </div>
);

export default class ErrorBoundaries extends React.Component {
  render() {
    return (
      <Provider config={rollbarConfig}>
        <ErrorBoundary level={LEVEL_WARN} fallbackUI={ErrorDisplay}>
          { this.props.children }
        </ErrorBoundary>
      </Provider>
    );
  }
}
