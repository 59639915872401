import MoreOptions from "./shared/MoreOptions";
import * as constants from "../constants";
import React, { Fragment } from "react";
import * as api from "../api";
import Avatar from "./Avatar";
import noBlockedUsersBackground from "../../assets/images/no-blocked-background.png";
import caretDown from "../../assets/images/caret-down.png";
import Alert from '@mui/material/Alert';

class BlockedUsersList extends React.Component {
  state = {
    isAscendingDateSort: false,
    blockedUsers: [],
    alert: false,
    type: 'success',
    message: ''
  };

  initializeBlockedUsers = () => {
    const blockedUsers = this.props.blockedUsers
      .map(blockedEntry => ({
        blockedAtDate: new Date(blockedEntry.created_at), // DateISOString
        screenName: blockedEntry.user.screen_name,
        imageURL: blockedEntry.user.avatar,
        fullName: blockedEntry.user.full_name,
        id: blockedEntry.id,
      }));

    this.setState({ blockedUsers });
  }

  notifyBlockedUserOnMount() {
    const key = constants.BLOCKED_USER_SESSION_KEY;
    const blockedToFullName = sessionStorage.getItem(key);
    if (blockedToFullName) {
      sessionStorage.removeItem(key);
    }
  }

  setAlert = (message,type) => {
    this.setState({alert: true, message: message, type: type});
    setTimeout(() => {
      this.setState({alert: false});
         }, 5000);
  }

  componentDidMount() {
    this.notifyBlockedUserOnMount();
    this.initializeBlockedUsers();
  }

  toggleDateSorting = () => {
    this.setState({
      isAscendingDateSort: !this.state.isAscendingDateSort
    });
  }

  filterUnblockedFromList = blockedEntryId => {
    this.setState({
      blockedUsers: this.state.blockedUsers
        .filter(({ id }) => id !== blockedEntryId)
    });
  }

  removeBlockedEntry = (blockedEntryId, fullName) => {
    api.unblockUser(
      this.props.currentUser.id, blockedEntryId
    ).done(() => {
      const message = `You have unblocked ${fullName}`;
      this.filterUnblockedFromList(blockedEntryId);
      this.setAlert(message,'success')
    }).fail(() => {
      const message = `Failed to unblock ${fullName}`;
      this.setAlert(message,'error')
    });
  }

  getMMDDYYYYDate = date => ([
    date.getMonth() + 1,
    date.getDate(),
    date.getFullYear()
  ].join("/"))

  renderBlockedUser({
    blockedAtDate,
    screenName,
    imageURL,
    fullName,
    id
  }) {
    const nameElement = (
      <div className="blocked-entry-name">
        <Avatar avatar={imageURL} size={42} />
        {fullName}
      </div>
    );
    const moreOptions = (
      <MoreOptions title="More Options" dotClasses="uk-center">
        <li onClick={() => this.removeBlockedEntry(id, fullName)}>
          <a href="#">Unblock User</a>
        </li>
      </MoreOptions>
    );

    return (
      <tr key={id}>
        <td>{nameElement}</td>
        <td>{screenName}</td>
        <td>{this.getMMDDYYYYDate(blockedAtDate)}</td>
        <td>{moreOptions}</td>
      </tr>
    );
  }

  prepareSortedList = isAscending => {
    const { blockedUsers } = this.state;
    return [...blockedUsers].sort((a, b) => {
      const diff = (
        a.blockedAtDate.getTime() -
        b.blockedAtDate.getTime()
      );
      return isAscending ? diff : -diff;
    });
  }

  render() {
    const _this = this;
    const isAscendingSort = _this.state.isAscendingDateSort;
    const dateCaretSuffix = isAscendingSort ? "asc" : "dsc"

    return (
      <Fragment>
        {_this.state.alert && ( <Alert severity={_this.state.type} className="fixedAlert" onClose={() => {this.setState({alert: false});}}>{_this.state.message}</Alert> )}
        {!_this.state.blockedUsers.length ? (
          <div className="blocked-entry-placeholder">
            <h2>You haven't blocked anyone!</h2>
            <img src={noBlockedUsersBackground} />
            <h5>
              If you would like to block a member, go to the member list in one of
              your hapynings and select <q>Block Member</q> from the actions menu.
            </h5>
          </div>
        ) : (
          <Fragment>
            <div className="table-body uk-overflow-auto member-view">
              <table className="uk-table uk-table-divider uk-table-middle">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Screen Name</th>
                    <th className="blocked-entry-date-heading">
                      Date Blocked
                      <img
                        className={`date-caret-${dateCaretSuffix} blocked-entry-date-caret`}
                        onClick={_this.toggleDateSorting}
                        src={caretDown}
                      />
                    </th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {_this.prepareSortedList(isAscendingSort)
                    .map(blockedEntry => (_this.renderBlockedUser(blockedEntry)))}
                </tbody>
              </table>
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default BlockedUsersList;