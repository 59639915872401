import React from 'react';
import Form from './richTexts/Form';
import { createRichText, createPost, updatePost } from '../api';
import Turbolinks from 'turbolinks';

const upsertPost = ({id, ...params}) => (id ? updatePost({...params, id}) : createPost(params));

export default class NewRichText extends React.Component {
  createRichText = async (state) => {
    const {
      preview_content,
      content,
      title,
      post_content,
      preview_image
    } = state;
    const {post_id, hapyning_id} = this.props;

    const {rich_text} = await createRichText({
      preview_content, title, content, preview_image
    });

    if (hapyning_id) {
      return new Promise((resolve, reject) => {
        upsertPost({
          id: post_id,
          content: post_content,
          hapyning_id: hapyning_id,
          attachable_id: rich_text.id,
          attachable_type: rich_text.type
        })
        .done(() => resolve(Turbolinks.visit(`/hapynings/${hapyning_id}`)))
        .fail((error) => reject(error));
      });
    } else {
      Turbolinks.visit(`/rich_texts/${rich_text.id}`);
    }
  }

  render() {
    return (
      <Form
        hapyningId={this.props.hapyning_id}
        post_content={this.props.post_content}
        save={this.createRichText} />
    );
  }
}
