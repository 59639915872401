import React from "react";
import Video from 'file-type-video@1.5x.svg'
import Audio from 'file-type-audio@1.5x.svg'
import Pdf from 'file-type-pdf@1.5x.svg'
import Excel from 'file-type-excel@1.5x.svg'
import Word from 'file-type-word@1.5x.svg'
import Image from 'file-type-image@1.5x.svg'
import Zip from 'file-type-zip@1.5x.svg'
import PowerPoint from 'file-type-powerpoint@1.5x.svg'
import FileIcon from 'file-icon-primary.svg';

export default function FileLibraryPreview({attachment}) {
  if (!attachment) return null;

  const pickIcon = (contentType) => {
    const [type] = contentType.split(".").slice(-1)
    switch ( type ) {
      case 'mov': case 'mp4': case 'avi':
        return Video
      case 'mp3':
        return Audio
      case 'pdf':
        return Pdf
      case 'csv': case 'xlx' : case 'xlsx':
        return Excel
      case 'png': case 'jpg': case 'jpeg': case 'gif': case 'webp':
        return Image
      case 'zip': case 'rar':
        return Zip
      case 'ppt': case 'pptx':
        return PowerPoint
      case 'doc': case 'docx':
        return Word
      default:
        return FileIcon
    }
  }

  return (
    <div className="post-body">
      <ul className="uk-list">
        {_.map(attachment.files, ({id=_.uniqueId('file-'), url, identifier}) => (
          <li key={id} className="uk-margin-top">
            <img src={pickIcon(identifier)} aria-hidden="true"></img>
            <a href={url} rel="nofollow" target="_blank" className="uk-margin-left">{identifier}</a>
          </li>
        ))}
      </ul>
    </div>
  );
}
