import React from 'react';
import { LocalTime } from './time';

export function Header({title, updated_at, created_at}) {
  return(
    <>
      <h2 className="post-article-preview-title text-limit">{title}</h2>
      {updated_at &&
        <p className="uk-text-meta uk-margin-remove-vertical">
          {updated_at > created_at ? "Last updated " : ""}
          <LocalTime time={updated_at} format="long"/>
        </p>
      }
    </>
  )
}
