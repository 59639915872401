export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

let pendingChanges = 0;

export const watchForChanges = () => {
  if (pendingChanges > 0) return;
  const $saveButton = $(document).find('.watch-changes-target');
  $saveButton.prop('disabled', false);
  $(document).find('.confirm-cancel').removeClass('uk-modal-close');
  $(document).find('.confirm-cancel').attr('data-uk-toggle', 'target: #confirm-abandon-modal');
}

export const setSaveButtonDisabledState = (newState) => {
  if (newState) pendingChanges++;
  else pendingChanges--;
  const $desktopSaveButton = $(document).find('.watch-changes-target');
  $desktopSaveButton.prop('disabled', newState);
}

jQuery(function($) {
  $.initialize("[data-character-counter]", function() {
    const max = $(this).data("character-counter");
    const display = $('<div class="field-info uk-float-right"/>');
    $(this).after(display);
    $(this).on("change keyup", function(e) {
      const currentLength = e.target.value.length;
      display.text(`${currentLength}/${max}`);
      display.toggleClass("uk-text-danger", currentLength > max);
    });
    $(this).trigger("change");
  });

  $(document).ready(validateFields);
  $(document).on('turbolinks:load', validateFields);

  const validators = {
    length: (field) => {
      const minLength = field.attr('minlength') || 1;
      const maxLength = field.attr('maxlength');
      return (minLength && field.val().length < minLength) ||
        (maxLength && field.val().length > maxLength);
    },
    email: (email) => !EMAIL_REGEX.test(email.val())
  };

  const fields = {
    'user_email': validators['email'],
    'user_full_name': validators['length'],
    'user_screen_name': validators['length'],
    'user_password': validators['length']
  };

  function validateFields() {
    _.map(fields, (test, fieldName) => {
      $(document).on('blur', `#${fieldName}`, function() {
        displayErrorMessage($(this), test);
      })
    });

    $(document).on('blur', '[data-validator]', function() {
      displayErrorMessage($(this), validators[$(this).data('validator')]);
    });

    $(document).on('blur', '#user_password_confirmation', function() {
      displayErrorMessage($(this), (field) => field.val() !== $("#user_password").val());
    });

    function displayErrorMessage(field, test) {
      test = test || validators[field.data('validator')];
      field.nextAll('.error-message').remove();
      var saveButton = $('.save-button');
      var mobileSaveButton = $('.mobile-save-button');

      if (test(field)) {
        saveButton.attr('disabled', true);
        mobileSaveButton.attr('disabled', true);
        const blankMessage = field.data('validation-message-blank') || field.data('validation-message');
        const message = field.val().length === 0 ? blankMessage : field.data('validation-message');
        field.after(`<div class="error-message">${message || ''}</div>`);
        field.addClass("has-error");
      } else {
        field.removeClass("has-error");
        saveButton.attr('disabled', false);
        mobileSaveButton.attr('disabled', false);
      }
    }
  }

  $.initialize(".watch-changes", function() {
    var $form = $(this);
    $form.find(".watch-changes-target").prop('disabled', true);
  });

  function confirmModalClose(modal) {
    const $closeButton = modal.find('.uk-close');
    $closeButton.removeClass('uk-modal-close-default');
    $closeButton.addClass('modal-close');
    $closeButton.attr('data-uk-toggle', 'target: #confirm-abandon-modal');

    const $mobileModal = modal.find('.mobile-form-navigation');
    const $mobileCloseButton = $mobileModal.find('.uk-modal-close');
    $mobileCloseButton.removeClass('uk-modal-close');
    $mobileCloseButton.attr('data-uk-toggle', 'target: #confirm-abandon-modal');
  }

  $(document).on('change', '.watch-changes :input, .watch-changes .trigger', function() {
    const $form = $(this).closest('.watch-changes');
    if (pendingChanges == 0) {
      $form.find('.watch-changes-target').prop('disabled', false);
    }
    $form.find('.confirm-cancel').removeClass('uk-modal-close');
    $form.find('.confirm-cancel').attr('data-uk-toggle', 'target: #confirm-abandon-modal');

    const $modal = $($form).closest('.uk-modal-dialog');
    confirmModalClose($modal);
  });

  $(document).on('click', '#abandon ', function() {
    const $modal = $(this).closest('body').find('#global-modal');
    const $closeButton = $modal.find('.uk-close');
    $closeButton.addClass('uk-modal-close-default');
    $closeButton.removeClass('modal-close');
    $closeButton.removeAttr('data-uk-toggle');
  });

  $(document).on('click', '#resource-modal-cancel', function() {
    const $videos = $(this).closest('body').find('.video-resource');
    if ($videos.length) $videos[0].pause();
  });
});

document.addEventListener("turbolinks:load", function() {
  let arr = [];
  $('#user_birthday_1i').on('change', function() {
    arr[0] = $('#user_birthday_1i').val();
      replaceSubmitButton(arr);
  });
  $('#user_birthday_2i').on('change', function() {
    arr[1] = $('#user_birthday_2i').val();
      replaceSubmitButton(arr);
  });
  $('#user_birthday_3i').on('change', function() {
    arr[2] = $('#user_birthday_3i').val();
      replaceSubmitButton(arr);
  });
});

function replaceSubmitButton(arr) {
  if(arr.filter(Boolean).length != 3) return ;
  if(validateAge(arr)) {
    if($('#age_requirement_anchor').length)
    $('#age_requirement_anchor').replaceWith("<button name='commit' type='submit' class='uk-button uk-button-primary' id='registration-submit'>Register New Account</button>")
  }
  else if($('#registration-submit').length)
    $('#registration-submit').replaceWith("<a href='#age_requirement_alert' class='uk-button uk-button-primary' id='age_requirement_anchor' uk-toggle>Register New Account</a>")

}

function validateAge(arr) {
  let today = new Date();
  let res = today.getFullYear() - arr[0];
  return res > 13 ? true : (res == 13 &&  (today.getMonth() + 1 > arr[1] || (today.getMonth() + 1 == arr[1] && today.getDate() >= arr[2])));
}
