import UIkit from 'uikit';

const LOADING_MODAL = `
  <div class="uk-text-center"><div uk-spinner="scale: 2"></div></div>
`;
const UPDATING_HAPYN = `
  <div class="uk-text-center"><h3>Updating Hapyn</h3><div uk-spinner="scale: 2"></div></div>
`;

jQuery(function($) {
  const modal = UIkit.modal($("#global-modal")[0]);
  const currentDeploy = $("meta[name=deploy-key]").attr("content");

  $(document).on("beforeshow", function(e) {
    const $clickTarget = $(e.target);

    if ($clickTarget.hasClass("uk-modal")) {
      $('[uk-dropdown]').each(function() {
        UIkit.dropdown(this).hide();
      });
    } else if ($clickTarget.hasClass("uk-offcanvas")) {
      $('[uk-dropdown]').each(function() {
        UIkit.dropdown(this).hide(false);
      });
    }
  });

  const openModal = function(e) {
    let thisModal = modal;
    e.preventDefault();
    const href = $(this).attr("href");
    const queryHref = /^\$\(([^\)]+\))$/;
    let $modalBody = $('<div>');
    if (queryHref.test(href)) {
      const selector = href.match(queryHref)[1];
      thisModal = UIKit.modal(selector);
    } else {
      $modalBody = $(thisModal.$el).find('.uk-modal-dialog');
      $modalBody.html(LOADING_MODAL);
      $modalBody.load(href + " #main-content > *", (response) => {
        const deployKey = $(response).filter("meta[name=deploy-key]").attr("content");
        if (deployKey !== currentDeploy) {
          $modalBody.html(UPDATING_HAPYN);
          return reloadEverything(href);
        }
        ReactRailsUJS.mountComponents();
      });
    }
    thisModal.show();

    function loadNext(e) {
      const [data, status, xhr] = e.detail;
      if (typeof data === "object") {
        const newContent = $(data).find("#main-content > *");
        if (!newContent.length) return;

        $modalBody.html(newContent);
        ReactRailsUJS.mountComponents();
        loadModalBody($modalBody);
      }
    }

    function loadModalBody($modalBody) {
      $modalBody
        .one("ajax:success", loadNext)
        .one("ajax:error", (e) => {
          console.warn("ajax failure", e.detail);
        });
    }

    loadModalBody($modalBody);
  }

  function resetStep(e) {
    $("#step").val("0");
    $(".save-button").click();
  }

  function closeModal(e) {
    e.preventDefault();
    modal.hide();
  }

  $(document).on("click", ".open-in-modal", openModal);
  $(document).on("click", ".back-button", resetStep);
  $(document).on("click", ".uk-modal-close", function(e) {
    if (document.hasFocus()) {
      $(":focus")[0].blur();
    }
  });

  $(document).on("click", "#global-modal [href='javascript:history.back()']", function(e) {
    closeModal(e);
  });

  function reloadEverything(currentModalPath) {
    localStorage.setItem('currentModalPath', currentModalPath);
    window.location.reload();
  }

  function restoreOpenModal() {
    const modalPath = localStorage.getItem('currentModalPath');
    if (modalPath) {
      $(`[href="${modalPath}"]`).first().click();
      localStorage.removeItem('currentModalPath');
    }
  }
  restoreOpenModal();
});
