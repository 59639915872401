import React from 'react';
import {hostnameFromUrl} from '../helpers/links';
import {stopEverything} from '../helpers/events';
import MaterialIcon from '../shared/MaterialIcon';

export default class LinkPreview extends React.Component {
  render() {
    const {preview, removePreview} = this.props;
    if (!preview || preview.loading) return null;
    const {image, title, url, description, error} = preview;
    const hostname = hostnameFromUrl(url);

    if (error) {
      return (
        <div className="link-preview">
          <div className="link-preview-content">
            <h2 className="link-preview-title">{title}</h2>
            <p className="link-preview-hostname">{hostname}</p>
          </div>
        </div>
      )
    } else {
      return (
        <div className="uk-position-relative">
          <a href={url} className="link-preview" target="_blank" rel="noopener noreferrer">
            {image && (
              <img src={image} className="link-preview-image" alt=""></img>
              )}
            <div className="link-preview-content">
              <h2 className="link-preview-title">{title}</h2>
              <p className="link-preview-hostname">{hostname}</p>
              {description && (
                <p className="link-preview-description">{description}</p>
                )}
            </div>
          </a>
          {removePreview && (
            <button type="button" onClick={stopEverything(removePreview)} title="Remove Preview" className="attachment-button" aria-label="remove this url preview">
              <MaterialIcon name="clear" />
            </button>
          )}
        </div>
      )
    }
  }
}
