import React from 'react';
import EmbeddedVideoPlayer from './posts/EmbeddedVideoPlayer';

export default function VideoPreview({attachment}) {
  if (!attachment) return null;
  const {videos, embed_urls} = attachment;

  if (_.first(embed_urls)) {
    return <EmbeddedVideoPlayer url={_.first(embed_urls)} removePreview={false} />;
  }

  const video = _.first(videos);
  return (
    <div>
      <video className="uk-width-1-1 video-resource embedded-video-container" src={video.url} controls/>
    </div>
  );
}
