import React from 'react';

const FileUploader = ({toggleShowDragDrop}) => (
  <label className="uploader uk-flex uk-flex-middle" data-test-tag="fileUploaderJs" onClick={() => toggleShowDragDrop(false)}>
    <span className="material-icons" aria-hidden="true">attach_file</span>
    <span className="screen-reader-text">Attach File</span>
  </label>
);

export default FileUploader;
