import React from 'react';

const ImageUploader = ({toggleShowDragDrop}) => (
  <label className="uploader" data-test-tag="imageUploaderJs" onClick={toggleShowDragDrop}>
    <span className="material-icons" aria-hidden="true">insert_photo</span>
    <span className="screen-reader-text">Attach Image</span>
  </label>
);

export default ImageUploader;
