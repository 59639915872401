import React from 'react';

const DISPLAYABLE_VIDEO_EXTENSIONS = ['mp4', 'mov'];
const EXTENSION_REGEX = /\.(\w+)$/;

const displayableVideo = (url) => {
  const extensionMatch = EXTENSION_REGEX.exec(url);
  return extensionMatch && _.includes(DISPLAYABLE_VIDEO_EXTENSIONS, _.toLower(extensionMatch[1]));
};

export default function FileAssetPreview({attachment}) {
  if (!attachment) return null;

  const {id, url, identifier} = attachment;

  return (
    <div className="post-attachments">
      {displayableVideo(url) ? (
        <video key={id} src={url} type="video/mp4" controls />
      ) : (
        <div key={id} className="file-attachment">
          <a href={url} className="file-link" target="_blank">
            <span className="material-icons" aria-hidden="true">insert_drive_file</span>
            <span className="file-identifier">{identifier}</span>
          </a>
        </div>
      )}
    </div>
  );
}
