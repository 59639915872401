import React from 'react';
import { v4 as uuid } from 'uuid';
import classnames from 'classnames';

import greenLink from "mdi-link-variant@1.5x.svg";
import resourceLink from "mdi-link-variant-gray-bkgd.svg";
import LinkForm from './LinkForm';

const OptionButton = ({ mainClass, onClick, iconClasses }) => (
  <button className={mainClass} onClick={onClick}>
    <i className={iconClasses} aria-hidden="true"></i>
  </button>
);

class LinkCollectionField extends React.Component {
  state = {
    addLinkKey: 0
  }

  addLink = ({ url, title }) => {
    const { setAttachable } = this.props;
    setAttachable((attachable) => ({
      ...attachable,
      links: [...attachable.links, { title, url, id: uuid() }]
    }));

    this.setState({ addLinkKey: !this.props.editing && this.state.addLinkKey + 1 });
  }

  removeLink = (id) => {
    const { setAttachable } = this.props;
    setAttachable((attachable) => ({
      ...attachable,
      links: _.reject(attachable.links, { id })
    }));
  }

  editLink = (id) => {
    const { setAttachable } = this.props;
    setAttachable((attachable) => ({
      ...attachable,
      links: attachable.links.map((link) => link.id !== id ? link : { ...link, editMode: true })
    }));
  }

  updateLink = ({ id, url, title }) => {
    const { setAttachable } = this.props;
    setAttachable((attachable) => ({
      ...attachable,
      links: _.map(attachable.links, (link) => link.id !== id ? link : { id, url, title })
    }));
  }

  enableAddLinkForm = () => this.setState({ addLinkKey: this.state.addLinkKey + 1 })

  render() {
    const { attachable, editing, isResource, linkUpload } = this.props;
    const { addLinkKey } = this.state;

    return (
      <React.Fragment>
        {
          linkUpload === undefined || linkUpload === true || (editing === true && linkUpload === false) ? (
            <div className="link-attachment-container">
              {!_.some(attachable.links, 'editMode') && (
                (editing && !addLinkKey) ? (
                  <button className="uk-button uk-button-secondary" onClick={this.enableAddLinkForm}>
                    Add Link
                  </button>
                ) : (
                  <LinkForm key={addLinkKey} saveButtonLabel="Add Link" onSave={this.addLink} />
                )
              )}
              <div className="uploaded-link-library">
                {_.map(attachable.links, ({ id, url, title, editMode }) => {
                  if (editMode) {
                    return (
                      <LinkForm
                        key={id}
                        id={id}
                        url={url}
                        title={title}
                        saveButtonLabel="Update Link"
                        onSave={this.updateLink} />
                    );
                  } else {
                    return (
                      <span key={id}>
                        <img src={isResource ? resourceLink : greenLink} aria-hidden="true" />
                        <a href={url}>{title || url}</a>
                        <OptionButton
                          mainClass="edit-link"
                          onClick={() => this.editLink(id)}
                          iconClasses={classnames('edit', { 'gray': isResource })}
                        />
                        <OptionButton
                          mainClass="remove-link"
                          onClick={() => { this.removeLink(id); }}
                          iconClasses={classnames('delete', { 'gray': isResource })}
                        />
                      </span>
                    );
                  }
                })}
              </div>
            </div>
          ) : null
        }
      </React.Fragment>
    );
  }
}

export default LinkCollectionField;
