let didScroll = false;

document.addEventListener("turbolinks:load", function() {
    document.getElementById("page-scroll")?.addEventListener("scroll", function(e) {
      didScroll = true;
  });
});

setInterval(function() {
  if(didScroll) {
    didScroll = false
    const panel = document.querySelector(".panel-sticky")
      panel.classList.toggle("is-pinned", document.getElementById("page-scroll").scrollTop > 0)
  }
}, 250);
