import React from 'react';
import MaterialIcon from '../shared/MaterialIcon';

const ImageField = ({attachable_url, removeFile}) => (
  <div className="uploaded-image">
    <img src={attachable_url} />
    <button onClick={removeFile} className="attachment-button" aria-label="remove this attachment">
      <MaterialIcon name="clear"/>
    </button>
  </div>
);

export default ImageField;
