import React from "react";
import AsyncCreatableSelect from 'react-select/async-creatable';
import Async from 'react-select/async';
import { components, createFilter, valueContainer } from 'react-select';
import { EMAIL_REGEX } from '../packs/form_helpers';
import Avatar from './Avatar';

const Option = ({ data, children, ...props }) => {
  if (data.__isNew__) return (
    <components.Option {...props} value={data.email?.toLowerCase()}>Add {data.email}</components.Option>
  );
  return <components.Option {...props} value={data.email?.toLowerCase()}>
    <Avatar avatar={data.avatar_url} size="40" className="invitable-member-avatar"/>
    <div>
      <div className="member-option-full-name">{data.full_name}</div>
      <div className="member-option-screen-name">{data.screen_name}</div>
    </div>
  </components.Option>
};

const isValidEmail = (email="") => email.match(EMAIL_REGEX);

const ValueContainer = ({ children=[], ...props }) => {
  const defaultChild = (key) => key === "placeholder" || key === null;

  let error = false;
  const childrenComponents = (
    <components.ValueContainer {...props}>
      {[
        React.Children.map(children[0], (child, i) => {
          if (!_.isNull(child)) {
            const itemData = _.get(child, "props.data", {});
            if (defaultChild(child.key) || isValidEmail(itemData.email) || _.isNumber(itemData.id)) {
              return <child.type {...child.props} key={i} />;
            } else {
              error = true;
              return <child.type {...child.props} key={i} className="error" />;
            }
          }
          return null;
        }),
        <components.Input {...children[1].props} type="email" key="inputKey" />
      ]}
    </components.ValueContainer>
  );

  $('.save-button').attr('disabled', !!error);

  return childrenComponents;
};

const isPotentialEmail = (txt) => /@/.test(txt)

const colleagues = (hapyning_id, query) => {
  if(isPotentialEmail(query))
    query = null;
  return $.get(`/api/v1/colleagues.json?hapyning_id=${hapyning_id}&q=${encodeURIComponent(query)}`);
};

const optionValue = (option) => {
  return _.isEmpty(option.email) ? option.id : option.email?.toLowerCase();
};

function MemberSelect(props) {
  const { name, is_searchable, hapyning_id } = props;
  const Select = is_searchable ? AsyncCreatableSelect : Async
  return (
    <Select
      isMulti
      name={name}
      className="basic-multi-select"
      classNamePrefix="select_option"
      defaultOptions
      loadOptions={(query) => colleagues(hapyning_id, query)}
      getNewOptionData={(email) => ({ email, __isNew__: true })}
      getOptionValue={optionValue}
      getOptionLabel={(option) => option.full_name || option.email}
      delimiter=","
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Option,
        ValueContainer
      }}
    />
  );
}

export default MemberSelect;
