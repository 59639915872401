import React from 'react';
import pluralize from 'pluralize';

import heartOutline from "heart-outline.svg";
import heartFilled from "heart-filled.svg";
import likedHeart from "liked-heart.svg";
import commentOutline from "comment-outline.svg";
import commentCount from "comment-count.svg";
import copyLink from "copy-link.svg";

const pluralText = (text, value) => pluralize(text, value, true);

class InteractionBar extends React.Component {
  state = {
    likedBy: this.props.liked_by,
    countLikes: this.props.likes
  }

  toggleLike = () => {
    const {post} = this.props;
    const {likedBy} = this.state;
    if (!likedBy) {
      this.setState(({countLikes}) => ({likedBy: true, countLikes: countLikes + 1}));
      return $.post({url: `/posts/${post.id}/likes`});
    } else {
      this.setState(({countLikes}) => ({likedBy: false, countLikes: countLikes - 1}));
      return $.ajax({url: `/posts/${post.id}/likes/0`, type: 'DELETE'});
    }
  }

  render() {
    const {likedBy, countLikes} = this.state;
    const {full_page_post_url, countComments, toggleComments, showComments, canComment} = this.props;
    const countCommentsText = pluralText("Comments", countComments);
    return (
      <>
        <div className="reactions-bar uk-flex">
          <div className="post-reaction" aria-label={`${countLikes} people like this post`}>
            <img className="post-reaction-icon" src={likedHeart}></img>
            <span className="counted-likes">{countLikes}</span>
          </div>
          {(canComment || (countComments > 0)) && <div className="post-reaction">
            <span className="screen-reader-text">{pluralText("Comments", countComments)} on this post</span>
            {showComments ? (
              <div aria-hidden="true">
                <img className="post-reaction-icon" src={commentCount}></img>
                <span className="counted-comments">{countCommentsText}</span>
              </div>
            ) : (
              <button className="show-comments" aria-label="View comments for this post" onClick={toggleComments(false)}>
                <img className="post-reaction-icon" src={commentCount}></img>
                <span className="counted-comments">{countCommentsText}</span>
              </button>
            )}
          </div>}
        </div>
        <div className="interaction-bar">
          <button onClick={this.toggleLike} aria-label={likedBy ? "Unlike this post" : "Like this post"}>
            <img src={likedBy ? heartFilled : heartOutline} aria-hidden="true"></img>
            Like
          </button>
          { canComment && <button onClick={toggleComments(true)} aria-label={showComments ? "Hide Comments" : "View Comments"}>
            <img src={commentOutline} aria-hidden="true"></img>
            Comment
          </button>}
          <button data-copy-text={full_page_post_url}>
            <img src={copyLink} aria-hidden="true"></img>
            Copy Link
          </button>
        </div>
      </>
    )
  }
}

export default InteractionBar;
