import React from 'react';
import { Icon } from '@iconify/react';
import fileVideo from '@iconify-icons/mdi/file-video';

import MaterialIcon from '../shared/MaterialIcon';

const VideoField = ({video, removeFile}) => (
  <div className="uploaded-video uk-flex uk-flex-column" data-test-tag='uploaded-video'>
    <Icon className="file-icon" icon={fileVideo} />
    <span className="uk-text-break">
      {video.identifier}
    </span>
    <button onClick={removeFile} className="attachment-button" aria-label="remove this attachment">
      <MaterialIcon name="clear" />
    </button>
  </div>
);

export default VideoField;
