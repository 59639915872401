export function preventDefault(method) {
  return (e) => {
    e.preventDefault();
    _.attempt(method(e));
  }
}

export function stopPropagation(method) {
  return (e) => {
    e.stopPropagation();
    _.attempt(method(e));
  }
}

export function stopEverything(method) {
  return preventDefault(stopPropagation(method));
}

export const getEventValue = (func, ...args) => ({target: {value}}) => func(...args, value);
