import React from 'react';
import UploadcareImage from './UploadcareImage';
import DEFAULT_THUMBNAIL from 'default-group-avatar.svg';

export default function HapyningThumbnail(props) {
  const {thumbnail, size=40, ...attributes} = props;
  return (
    <UploadcareImage
      image={thumbnail}
      defaultImage={DEFAULT_THUMBNAIL}
      width={size}
      height={size}
      circle={false}
      {...attributes}/>
  );
}
