import React, {useState} from 'react';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

const DragAndDrop = (props) => {
  const { initiateUpload, attachable, accept, uploadtype } = props;

  const [dropZone, setDropZone] = useState(false)
  var dropDepth = 0;

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dropDepth = dropDepth + 1;
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dropDepth = dropDepth - 1
    if (dropDepth > 0) {
      return
    }
    setDropZone(false)
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = 'copy';
    setDropZone(true);
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let files = {target: e.dataTransfer};
    initiateUpload(files);
    setDropZone(false);
  };

  const handleDropPost = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let files = {target: e.dataTransfer};
    setDropZone(false);
    if (attachable && attachable?.images?.length > 0 && _.every(attachable?.images, (f) => f.content_type.startsWith("image/")) && !_.every(files.target.files, (f) => f.type.startsWith("image/")) ) {
      return;
    }
    if (attachable && attachable?.videos?.length == 1 && _.every(attachable?.images).type.startsWith("video/") )
    {
      return;
    }
    initiateUpload(files);
  };

  return <>
  <div  className={dropZone ? 'inside-drag-area' : ''}
        onDrop={e => props.post ? handleDropPost(e) : handleDrop(e)}
        onDragOver={e => handleDragOver(e)}
        onDragEnter={e => handleDragEnter(e)}
        onDragLeave={e => handleDragLeave(e)}
        >
    {props.children}
    {!props.post && <>
      <div className='drag-drop-container uk-flex-column uk-margin-top'>
      <span className='uk-margin-medium-top uk-margin-small-bottom'><CloudUploadOutlinedIcon  sx={{ fontSize: "29px" }} /></span>
                    <div className='uk-margin-small-bottom'>Drag and drop files here</div>
                    <div className='uk-margin-small-bottom'>or</div>
                    <label className="uk-button uk-button-secondary" data-upload-type={uploadtype} data-test-tag='drag-n-drop'>
                      <input type="file" accept={accept} onChange={initiateUpload} hidden multiple/>
                    BROWSE FILES</label>

                  </div>
    </>}
  </div>
  </>;
}

export default DragAndDrop;
